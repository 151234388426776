import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
import colorpicker from 'bootstrap-colorpicker';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onInput_6df59f9b() {
    refreshStyle();
}

function onShopChange_6df59f9b() {
    let shop = $("#design-shop").val();
    let designHelper = $('#design_helper');
    let pickers = $('.colorpicker-group');

    $('#preview').prop('src', designHelper.data('stub') + shop + $('#action').val());

    pickers.find('input').prop('disabled', false);
    pickers.colorpicker({
        format: "rgb",
        popover: false,
        inline: true,
        container: '.colorpicker-group'
    });

    $("#email-hide-personal").prop("disabled", false);

    $.ajax({
        url: designHelper.data('get'),
        type: "post",
        async: true,
        data: {"shop": shop}
    }).done(function (response) {
        if (response.success) {
            $("#email-header-bg-color").val(response.data.header_bg_color);
            $("#email-header-text-color").val(response.data.header_text_color);
            $("#email-heading-color").val(response.data.heading_color);
            $("#email-hide-personal").val(response.data.hide_personal);

            refreshStyle();
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonED_6df59f9b(event) {
    toggleLoading('#saveButtonED', '#loadingButtonED');
    event.preventDefault();
    let designHelper = $('#design_helper');
    let form = $('#email-design-form');
    let inputs = $(document).find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = designHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonED', '#loadingButtonED');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initEmailDesignPage() {
    let lastHeight = 0, curHeight = 0, preview = $('#preview');

    window.colorpicker = colorpicker;

    window.refreshStyle = function () {
        preview.contents().find('.template_header_bg').css('background-color', $("#email-header-bg-color").val() || '#ff6566');
        preview.contents().find('.template_header_text').css('color', $("#email-header-text-color").val() || '#ffffff');
        preview.contents().find('.template_heading').css('color', $("#email-heading-color").val() || '#ff6566');
        preview.contents().find('.template_personal').css('display', $("#email-hide-personal").val() || 'block');
    };

    setInterval(function () {
        curHeight = preview.contents().find('body').height();
        if (curHeight !== lastHeight) {
            lastHeight = curHeight;
            preview.css('height', lastHeight + 'px');
        }
    }, 500);

    $("#design-shop").on("change", onShopChange_6df59f9b);
    $("#saveButtonED").on("click", onSaveButtonED_6df59f9b);
    $('#email-design-form input, #email-design-form select').on('change', onInput_6df59f9b);
}

export function unloadEmailDesignPage() {
    delete window.colorpicker;
    delete window.refreshStyle;

    $("#design-shop").off("change", onShopChange_6df59f9b);
    $("#saveButtonED").off("click", onSaveButtonED_6df59f9b);
    $('#email-design-form input, #email-design-form select').off('change', onInput_6df59f9b);
}
