import 'bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
import colorpicker from 'bootstrap-colorpicker';
import {swalError, swalSuccess, swalUnknown} from "../functions";

function onConfigurationSubmit_312a6b0c(event) {
    event.preventDefault();
    let form = $(this);
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = $('#helper').data('url');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initShopSettingsPage() {
    window.colorpicker = colorpicker;

    $(".colorpicker-group").colorpicker({
        format: "rgb",
        popover: false,
        inline: true,
        container: '.colorpicker-group'
    }).on('change', function () {
        $(this).closest('.shop-configuration').children('.col-lg-6').children('.badge').css('color', '#ffffff').css('background-color', $(this).find('input').val() || '#7367F0');
    });

    $(".shop-configuration").on('submit', onConfigurationSubmit_312a6b0c);
}

export function unloadShopSettingsPage() {
    delete window.colorpicker;

    $(".shop-configuration").off('submit', onConfigurationSubmit_312a6b0c);
}
