import '../../app/vendors/css/forms/select/select2.min.css';
import select2 from 'select2';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onTestCredentials_b3c24e5f(event) {
    event.preventDefault();
    let helper = $('#helper');
    let form = $("#new-organisation-shop-form");
    let inputs = form.find("input, select, button, textarea");
    let serializedData = "url=" + $("#url").val() + "&key1=" + $("#key1").val() + "&key2=" + $("#key2").val() +
        "&shop_type=" + $("#shop-type").val() + "&api_version=" + $("#api-version").val();
    inputs.prop("disabled", true);

    $.ajax({
        url: helper.data('test'),
        type: "post",
        data: serializedData,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonNGS_b3c24e5f(event) {
    let form = $("#new-organisation-shop-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewShopForm_b3c24e5f(event) {
    toggleLoading('#saveButtonNGS', '#loadingButtonNGS');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let fd = new FormData(form[0]);
    let url = helper.data('add');
    let inputs = $(this).find("input:not(:hidden), select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            showGroupShops();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNGS', '#loadingButtonNGS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onTestCredentialsModal_b3c24e5f(event) {
    event.preventDefault();
    let helper = $('#helper');
    let form = $("#change-organisation-shop-form");
    let inputs = form.find("input, select, button, textarea");
    let serializedData = "url=" + $("#change-organisation-shop-form #url").val() + "&key1=" + $("#change-organisation-shop-form #key1").val() +
        "&key2=" + $("#change-organisation-shop-form #key2").val() + "&shop_type=" + $("#change-organisation-shop-form #shop-type").val() +
        "&api_version=" + $("#change-organisation-shop-form #api-version").val();
    inputs.prop("disabled", true);

    $.ajax({
        url: helper.data('test'),
        type: "post",
        data: serializedData,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCGS_b3c24e5f(event) {
    let form = $("#change-organisation-shop-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeShopForm_b3c24e5f(event) {
    toggleLoading('#saveButtonCGS', '#loadingButtonCGS');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let fd = new FormData(form[0]);
    let url = helper.data('edit');
    let inputs = $(this).find("input:not(:hidden), select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            showGroupShops();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCGS', '#loadingButtonCGS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initManageShopsPage() {
    let helper = $('#helper');

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
    }

    window.select2 = select2;

    window.showGroupShops = function () {
        $.ajax({
            url: helper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#group-shop-list").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeInput = function (id) {
        $.ajax({
            url: helper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#shop-id").val(id);
                $("#change-shop-modal-div").html(response.data);
                $('.select2').select2({minimumResultsForSearch: -1});
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    showGroupShops();
    $('.select2').select2({minimumResultsForSearch: -1});

    $("#test-credentials").on("click", onTestCredentials_b3c24e5f);
    $("#saveButtonNGS, #test-credentials").on("click", onSaveButtonNGS_b3c24e5f);
    $("#new-organisation-shop-form").on("submit", onNewShopForm_b3c24e5f);
    $("#test-credentials-modal").on("click", onTestCredentialsModal_b3c24e5f);
    $("#saveButtonCGS, #test-credentials-modal").on("click", onSaveButtonCGS_b3c24e5f);
    $("#change-organisation-shop-form").on("submit", onChangeShopForm_b3c24e5f);
}

export function unloadManageShopsPage() {
    delete window.select2;
    delete window.showGroupShops;
    delete window.changeInput;

    $('.select2.select2-hidden-accessible').select2('destroy');
    $("#test-credentials").off("click", onTestCredentials_b3c24e5f);
    $("#saveButtonNGS, #test-credentials").off("click", onSaveButtonNGS_b3c24e5f);
    $("#new-organisation-shop-form").off("submit", onNewShopForm_b3c24e5f);
    $("#test-credentials-modal").off("click", onTestCredentialsModal_b3c24e5f);
    $("#saveButtonCGS, #test-credentials-modal").off("click", onSaveButtonCGS_b3c24e5f);
    $("#change-organisation-shop-form").off("submit", onChangeShopForm_b3c24e5f);
}
