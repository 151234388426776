import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import flatpickr from "flatpickr";
import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import _ from "lodash";

function onSaveButtonNIE_39c35567(event) {
    let form = $("#new-inventory-entry-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onInventoryEntrySubmit_39c35567(event) {
    toggleLoading('#saveButtonNIE', '#loadingButtonNIE');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#myTable').DataTable().ajax.reload(null, false);

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNIE', '#loadingButtonNIE');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initInventoryPage() {
    window.flatpickr = flatpickr;

    let helper = $('#helper');

    require('../datatables');

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    $('#new-inventory-entry-date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    let table = $('#myTable').DataTable({
        "responsive": true,
        "language": {
            "url": helper.data('json')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                'targets': 0,
                'responsivePriority': 1
            },
            {
                'targets': 2,
                'responsivePriority': 2
            }
        ],
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": helper.data('entries')
    }).on('draw.dt', function () {
        feather.replace({
            width: 14,
            height: 14
        });

        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.deleteEntry = function (entry) {
        deleteDialog(entry, helper.data('irrev'), helper.data('confirm'), helper.data('delete'), helper.data('delete-url'), () => {
            table.ajax.reload(null, false);
        });
    };

    $("#saveButtonNIE").on("click", onSaveButtonNIE_39c35567);
    $("#new-inventory-entry-form").on("submit", onInventoryEntrySubmit_39c35567);
}

export function unloadInventoryPage() {
    delete window.deleteEntry;
    delete window.flatpickr;

    $('#myTable').DataTable().destroy();
    $('.flatpickr-calendar').remove();

    $("#saveButtonNIE").off("click", onSaveButtonNIE_39c35567);
    $("#new-inventory-entry-form").off("submit", onInventoryEntrySubmit_39c35567);
}
