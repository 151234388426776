import feather from "feather-icons";
import {deleteDialog, swalError, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonCFS_8afdef71(event) {
    let form = $("#print-label-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onGlsPrintLabelForm_8afdef71(event) {
    toggleLoading('#saveButtonCFS', '#loadingButtonCFS');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let fd = new FormData(form[0]);
    let url = helper.data('print');

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            let a = document.createElement('a');

            if (window.URL && window.Blob && ('download' in a) && window.atob) {
                let blob = base64ToBlob(response.data.data, response.data.mimetype);
                let url = window.URL.createObjectURL(blob);
                a.href = url;
                a.download = response.data.filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }

            form.removeClass("was-validated");
        } else {
            swalError(response.data);
        }
    }).always(function () {
        toggleLoading('#saveButtonCFS', '#loadingButtonCFS');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initGlsParcelsPage() {
    require('../datatables');

    let helper = $('#helper');

    window.createSticker = function (tracking_code) {
        $("#print-label-tracking-code").val(tracking_code);
        $("#print-label-tracking-code-span").html(tracking_code);
    };

    window.base64ToBlob = function (base64, mimetype, slicesize) {
        if (!window.atob || !window.Uint8Array) {
            return null;
        }

        let mime = mimetype || '';
        let chunk = slicesize || 512;
        let bytechars = atob(base64);
        let bytearrays = [];

        for (let offset = 0; offset < bytechars.length; offset += chunk) {
            let slice = bytechars.slice(offset, offset + chunk);
            let bytenums = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytenums[i] = slice.charCodeAt(i);
            }
            bytearrays[bytearrays.length] = new Uint8Array(bytenums);
        }

        return new Blob(bytearrays, {type: mime});
    };

    window.deleteParcel = function (tracking_code, order_id) {
        deleteDialog(tracking_code, order_id, helper.data('delete-confirm'), helper.data('delete'), helper.data('delete-url'), () => {
        });
    };

    $('#myTable').dataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "dom": 't<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "orderCellsTop": false,
        "paging": false,
        "ordering": false,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searching": false,
        "ajax": helper.data('url')
    }).on('draw.dt', function () {
        $('.dataTables_filter .form-control').removeClass('form-control-sm');
        $('.dataTables_length .form-select').removeClass('form-select-sm').removeClass('form-control-sm');

        feather.replace({
            width: 14,
            height: 14
        });
    });

    $("#saveButtonCFS").on("click", onSaveButtonCFS_8afdef71);
    $("#print-label-form").on("submit", onGlsPrintLabelForm_8afdef71);
}

export function unloadGlsParcelsPage() {
    delete window.createSticker;
    delete window.base64ToBlob;
    delete window.deleteParcel;

    $('#myTable').DataTable().destroy();

    $("#saveButtonCFS").off("click", onSaveButtonCFS_8afdef71);
    $("#print-label-form").off("submit", onGlsPrintLabelForm_8afdef71);
}
