import '../../app/vendors/css/editors/quill/quill.snow.css';
import '../../app/css/plugins/forms/form-quill-editor.css';
import Quill from 'quill';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onResetButton_e381be18() {
    $('#full-container .editor').data('quill').setContents([{insert: '\n'}]);
}

function onSubmitButton_e381be18(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let contact = $('#contact');
    let form = $('#contact-message-form');
    let fd = new FormData(form[0]);
    let inputs = $(document).find("input, select, button, textarea");
    let url = contact.data('url');

    inputs.prop("disabled", true);
    fd.append('text', $('.ql-editor').html());

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            $('#full-container .editor').data('quill').setContents([{insert: '\n'}]);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initContactPage() {
    window.Quill = Quill;

    let editor = new Quill('#full-container .editor', {
        bounds: '#full-container .editor',
        modules: {
            toolbar: [
                [{font: []}, {size: []}],
                ['bold', 'italic', 'underline', 'strike'],
                [{color: []}, {background: []}],
                [{script: 'super'}, {script: 'sub'}],
                [{header: '1'}, {header: '2'}, 'blockquote', 'code-block'],
                [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
                ['direction', {align: []}],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ]
        },
        theme: 'snow'
    });

    $('#full-container .editor').data('quill', editor);

    $("button[type='reset']").on("click", onResetButton_e381be18);
    $("button[type='submit']").on("click", onSubmitButton_e381be18);
}

export function unloadContactPage() {
    delete window.Quill;

    $("button[type='reset']").off("click", onResetButton_e381be18);
    $("button[type='submit']").off("click", onSubmitButton_e381be18);
}
