import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";


function onSaveButtonNPT_d4ebb0a0(event) {
    let form = $("#new-product-type-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewProductTypeForm_d4ebb0a0(event) {
    toggleLoading('#saveButtonNPT', '#loadingButtonNPT');
    event.preventDefault();
    let form = $(this);
    let types = $('#types-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = types.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showProductTypes();
            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNPT', '#loadingButtonNPT');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCPT_d4ebb0a0(event) {
    let form = $("#change-product-type-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeProductTypeForm_d4ebb0a0(event) {
    toggleLoading('#saveButtonCPT', '#loadingButtonCPT');
    event.preventDefault();
    let form = $(this);
    let types = $('#types-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = types.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showProductTypes();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCPT', '#loadingButtonCPT');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initProductTypesPage() {
    let types = $('#types-helper');

    window.showProductTypes = function () {
        $.ajax({
            url: types.data('div'),
            type: 'post',
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#product-types-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeProductType = function (id) {
        $.ajax({
            url: types.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-product-type-modal .modal-body").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteProductType = function (id, name) {
        deleteDialog(id, decodeURI(name), types.data('delete-confirm'), types.data('delete'), types.data('delete-url'), () => {
            showProductTypes();
        });
    };

    showProductTypes();

    $("#saveButtonNPT").on("click", onSaveButtonNPT_d4ebb0a0);
    $("#new-product-type-form").on("submit", onNewProductTypeForm_d4ebb0a0);
    $("#saveButtonCPT").on("click", onSaveButtonCPT_d4ebb0a0);
    $("#change-product-type-form").on("submit", onChangeProductTypeForm_d4ebb0a0);
}

export function unloadProductTypesPage() {
    delete window.showProductTypes;
    delete window.changeProductType;
    delete window.deleteProductType;

    $("#saveButtonNPT").off("click", onSaveButtonNPT_d4ebb0a0);
    $("#new-product-type-form").off("submit", onNewProductTypeForm_d4ebb0a0);
    $("#saveButtonCPT").off("click", onSaveButtonCPT_d4ebb0a0);
    $("#change-product-type-form").off("submit", onChangeProductTypeForm_d4ebb0a0);
}
