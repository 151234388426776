import {swalError, swalUnknown} from "../functions";
import feather from "feather-icons";

function onKeypress_9aa2e32d(e) {
    return e.which !== 13;
}

function onCharModified_9aa2e32d() {
    if ($(this).hasClass('unit')) {
        let prev = parseInt($(this).siblings('td.prev').html(), 10);
        let current = parseInt($(this).html(), 10);
        $(this).siblings('td.diff').html(current - prev);
    }

    let table = $('#myTable').DataTable();
    let id = $('#entry').val();
    let helper = $('#helper');
    let row = table.page.info().page * table.page.info().length;

    $('#myTable tbody tr').each(function () {
        let i = 0;

        $(this).children('td').each(function () {
            if ([5, 6, 7, 8].includes(i)) {
                window.data[row][i] = $(this).text();
            }

            i++;
        });

        row++;
    });

    clearTimeout(window.timer);

    window.timer = setTimeout(function () {
        $.ajax({
            url: helper.data('edit'),
            type: "post",
            async: true,
            data: "data=" + encodeURIComponent(JSON.stringify(window.data.toArray())) + "&id=" + id
        }).done(function (response) {
            if (response.success) {
                Swal.fire({
                    position: 'top-end',
                    toast: true,
                    icon: 'success',
                    showConfirmButton: false,
                    timer: 2000,
                    title: response.message
                }).then();

                if (response.reload) {
                    setTimeout(function () {
                        location.reload();
                    }, 3000);
                }
            } else {
                swalError(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    }, 5000);
}

export function initInventoryEntryPage() {
    let helper = $('#helper');
    let table = $('#myTable');

    if (Number.parseInt(helper.data('complete'), 10) === 0) {
        window.timer = null;

        require('../datatables');

        table.DataTable({
            "responsive": true,
            "language": {
                "url": helper.data('lang')
            },
            "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
            "order": [[0, "asc"]],
            "buttons": [
                {
                    extend: 'collection',
                    className: 'btn btn-outline-secondary me-2',
                    text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + helper.data('export'),
                    buttons: [
                        {
                            extend: 'print',
                            text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + helper.data('print'),
                            className: 'dropdown-item',
                            exportOptions: {
                                columns: [0, 1, 2, 3, 4, 5, 6, 7, 8],
                                modifier: {
                                    order: 'current',
                                    page: 'all',
                                    selected: null
                                }
                            },
                            orientation: 'landscape',
                            customize: function (win) {
                                let css = '@page { size: landscape; }',
                                    head = win.document.head || win.document.getElementsByTagName('head')[0],
                                    style = win.document.createElement('style');
                                style.type = 'text/css';
                                style.media = 'print';
                                if (style.styleSheet) {
                                    style.styleSheet.cssText = css;
                                } else {
                                    style.appendChild(win.document.createTextNode(css));
                                }
                                head.appendChild(style);
                            }
                        },
                        {
                            extend: 'csvHtml5',
                            text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                            className: 'dropdown-item',
                            exportOptions: {
                                columns: [1, 2, 3, 4, 5, 6, 7, 8],
                                modifier: {
                                    order: 'current',
                                    page: 'all',
                                    selected: null
                                }
                            }
                        },
                        {
                            extend: 'excelHtml5',
                            text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                            className: 'dropdown-item',
                            exportOptions: {
                                columns: [1, 2, 3, 4, 5, 6, 7, 8],
                                modifier: {
                                    order: 'current',
                                    page: 'all',
                                    selected: null
                                }
                            }
                        },
                        {
                            extend: 'pdfHtml5',
                            text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                            className: 'dropdown-item',
                            exportOptions: {
                                columns: [1, 2, 3, 4, 5, 6, 7, 8],
                                modifier: {
                                    order: 'current',
                                    page: 'all',
                                    selected: null
                                }
                            }
                        },
                        {
                            extend: 'copyHtml5',
                            text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + helper.data('copy'),
                            className: 'dropdown-item',
                            exportOptions: {
                                columns: [1, 2, 3, 4, 5, 6, 7, 8],
                                modifier: {
                                    order: 'current',
                                    page: 'all',
                                    selected: null
                                }
                            }
                        }
                    ],
                    init: function (api, node) {
                        $(node).removeClass('btn-secondary');
                        $(node).parent().removeClass('btn-group');
                        setTimeout(function () {
                            $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                        }, 50);
                    }
                }
            ],
            "columnDefs": [
                {
                    'targets': 'no-sort',
                    'orderable': false
                },
                {
                    targets: 4,
                    responsivePriority: 2,
                },
                {
                    targets: 1,
                    responsivePriority: 1
                },
                {
                    targets: 'hidden',
                    visible: false
                }
            ],
            "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
            "pageLength": 25,
            "orderCellsTop": false,
            "info": true,
            "paging": true,
            "stateSave": true,
            "deferRender": true
        }).on('draw.dt', function () {
            $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
            $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
        });

        window.data = table.DataTable().rows().data();

        $("#myTable").on('keypress', onKeypress_9aa2e32d);
        $('#myTable td').on('DOMCharacterDataModified', onCharModified_9aa2e32d);
    }
}

export function unloadInventoryEntryPage() {
    if (Number.parseInt(helper.data('complete'), 10) === 0) {
        delete window.timer;

        $('#myTable').DataTable().destroy();

        $("#myTable").off('keypress', onKeypress_9aa2e32d);
        $('#myTable td').off('DOMCharacterDataModified', onCharModified_9aa2e32d);
    }
}
