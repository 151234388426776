import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import _ from "lodash";

function onSaveButtonNBL_13387881(event) {
    let form = $("#new-blacklisted-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonCBL_13387881(event) {
    let form = $("#change-blacklisted-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewBlacklistedForm_13387881(event) {
    toggleLoading('#saveButtonNBL', '#loadingButtonNBL');
    event.preventDefault();
    let form = $(this);
    let blacklist = $('#blacklist-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = blacklist.data('new');

    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#blacklistTable').DataTable().ajax.reload(null, false);

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNBL', '#loadingButtonNBL');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onChangeBlacklistedForm_13387881(event) {
    toggleLoading('#saveButtonCBL', '#loadingButtonCBL');
    event.preventDefault();
    let form = $(this);
    let blacklist = $('#blacklist-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = blacklist.data('edit');

    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#blacklistTable').DataTable().ajax.reload(null, false);
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCBL', '#loadingButtonCBL');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initBlacklistedPage() {
    require('../datatables');
    let blacklist = $('#blacklist-helper');

    let table = $('#blacklistTable').DataTable({
        "responsive": true,
        "language": {
            "url": blacklist.data('lang')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                'targets': 0,
                'responsivePriority': 1
            },
            {
                'targets': 1,
                'responsivePriority': 2
            }
        ],
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, blacklist.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "stateSave": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": blacklist.data('url')
    }).on('draw.dt', function () {
        feather.replace({
            width: 14,
            height: 14
        });

        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.changeInput = function (id) {
        $.ajax({
            url: blacklist.data('name'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-blacklisted-id").val(id);
                $("#change-blacklisted-name").val(response.data.name);
                $("#change-blacklisted-note").val(response.data.note);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteInput = function (id, name) {
        deleteDialog(id, decodeURI(name), blacklist.data('delete-confirm'), blacklist.data('delete'), blacklist.data('delete-url'), () => {
            table.ajax.reload(null, false);
        });
    };

    window.viewOrders = function (id) {
        $.ajax({
            url: blacklist.data('orders'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#view-blacklisted-orders-div").html(response.data);
            }

            feather.replace({
                width: 14,
                height: 14
            });
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    $("#saveButtonNBL").on("click", onSaveButtonNBL_13387881);
    $("#saveButtonCBL").on("click", onSaveButtonCBL_13387881);

    $("#new-blacklisted-form").on("submit", onNewBlacklistedForm_13387881);
    $("#change-blacklisted-form").on("submit", onChangeBlacklistedForm_13387881);
}

export function unloadBlacklistPage() {
    delete window.viewOrders;
    delete window.deleteInput;
    delete window.changeInput;

    $('#blacklistTable').DataTable().destroy();

    $("#saveButtonNBL").off("click", onSaveButtonNBL_13387881);
    $("#saveButtonCBL").off("click", onSaveButtonCBL_13387881);

    $("#new-blacklisted-form").off("submit", onNewBlacklistedForm_13387881);
    $("#change-blacklisted-form").off("submit", onChangeBlacklistedForm_13387881);
}