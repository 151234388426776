import feather from "feather-icons";

export function initBillingoDashboardPage() {
    require('../datatables');

    let helper = $('#helper');

    $('#myTable').dataTable({
        "responsive": true,
        "language": {
            "url": helper.data('json')
        },
        "dom": 't<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "lengthMenu": [[20], [20]],
        "orderCellsTop": false,
        "paging": true,
        "ordering": false,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searching": false,
        "ajax": helper.data('url')
    }).on('draw.dt', function () {
        $('.dataTables_filter .form-control').removeClass('form-control-sm');
        $('.dataTables_length .form-select').removeClass('form-select-sm').removeClass('form-control-sm');

        feather.replace({
            width: 14,
            height: 14
        });
    });
}

export function unloadBillingoDashboardPage() {
    $('#myTable').DataTable().destroy();
}
