import {deleteDialog, showLoading, swalError, swalUnknown} from "../functions";
import _ from "lodash";

export function initDeliveryRecordsPage() {
    let helper = $('#helper');

    require('../datatables');

    let table = $('#myTable').DataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "columnDefs": [{
            'targets': 'no-sort',
            'orderable': false
        }],
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": helper.data('table')
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');

        feather.replace({
            width: 14,
            height: 14
        });
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.deleteRecord = function (record) {
        deleteDialog(record, helper.data('irrev'), helper.data('confirm'), helper.data('del'), helper.data('delete'), () => {
            table.ajax.reload(null, false);
        });
    };

    window.base64ToBlob = function (base64, mimetype, slicesize) {
        if (!window.atob || !window.Uint8Array) {
            return null;
        }

        let mime = mimetype || '';
        let chunk = slicesize || 512;
        let bytechars = atob(base64);
        let bytearrays = [];

        for (let offset = 0; offset < bytechars.length; offset += chunk) {
            let slice = bytechars.slice(offset, offset + chunk);
            let bytenums = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytenums[i] = slice.charCodeAt(i);
            }
            bytearrays[bytearrays.length] = new Uint8Array(bytenums);
        }

        return new Blob(bytearrays, {type: mime});
    };

    window.downloadPackage = function ($package) {
        showLoading();
        let fd = new FormData();
        let url = helper.data('download');

        fd.append('package', $package);

        $.ajax({
            url: url,
            type: "post",
            data: fd,
            async: true,
            cache: false,
            contentType: false,
            processData: false
        }).done(function (response) {
            Swal.close();
            if (response.success) {
                let a = document.createElement('a');
                if (window.URL && window.Blob && ('download' in a) && window.atob) {
                    let blob = base64ToBlob(response.data.data, response.data.mimetype);
                    let url = window.URL.createObjectURL(blob);
                    a.href = url;
                    a.download = response.data.filename;
                    a.click();
                    window.URL.revokeObjectURL(url);
                }
            } else {
                swalError(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };
}

export function unloadDeliveryRecordsPage() {
    $('#myTable').DataTable().destroy();

    delete window.downloadPackage;
    delete window.deleteRecord;
    delete window.base64ToBlob;
}