import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";


function onSaveButton_ce2e3516(event) {
    let form = $("#new-partner-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewPartnerForm_ce2e3516(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let fd = new FormData(form[0]);
    let url = $('#helper').data('url');
    let inputs = $(this).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            form.trigger("reset");
            form.removeClass("was-validated");
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initNewPartnerPage() {
    $("#saveButton").on("click", onSaveButton_ce2e3516);
    $("#new-partner-form").on('submit', onNewPartnerForm_ce2e3516);
}

export function unloadNewPartnerPage() {
    $("#saveButton").off("click", onSaveButton_ce2e3516);
    $("#new-partner-form").off('submit', onNewPartnerForm_ce2e3516);
}
