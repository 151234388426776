import feather from "feather-icons";
import Tooltip from "bootstrap/js/src/tooltip";
import {deleteDialog, swalError, swalSuccess, swalUnknown} from "../functions";
import _ from "lodash";

export function initEmailLogPage() {
    require('../datatables');

    let helper = $('#helper');
    let table = $('#emailTable').DataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                'targets': 0,
                'responsivePriority': 1
            },
            {
                'targets': 3,
                'responsivePriority': 2
            }
        ],
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": helper.data('url')
    }).on('draw.dt', function () {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        feather.replace({
            width: 14,
            height: 14
        });

        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    }).on('responsive-display', function () {
        let tooltipTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

        tooltipTriggerList.map(function (tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        });

        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.resendMessage = function (message) {
        let swalWithBootstrapButtons = Swal.mixin({
            customClass:
                {
                    confirmButton: "btn btn-primary me-2",
                    cancelButton: "btn btn-secondary"
                },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: helper.data('resend-conf'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: helper.data('send'),
            cancelButtonText: helper.data('cancel')
        }).then(function (result) {
            if (result.value) {
                $.ajax({
                    url: helper.data('resend-url'),
                    type: "post",
                    data: "ID=" + message,
                    async: true
                }).done(function (response) {
                    if (response.success) {
                        table.ajax.reload(null, false);
                        swalSuccess(response.data);
                    } else {
                        swalError(response.data);
                    }
                }).fail(function (e) {
                    swalUnknown(e);
                });
            }
        });
    };

    window.deleteMessage = function (message) {
        deleteDialog(message, helper.data('irrev'), helper.data('delete-conf'), helper.data('delete'), helper.data('delete-url'), () => {
            table.ajax.reload(null, false);
        });
    };

    window.showMessage = function (id) {
        $.ajax({
            url: helper.data('show-sent'),
            type: "post",
            data: "ID=" + id,
            async: true
        }).done(function (response) {
            $("#show-sent-message-modal .modal-body").html(response.data);
        }).fail(function (e) {
            swalUnknown(e);
        });
    };
}

export function unloadEmailLogPage() {
    delete window.resendMessage;
    delete window.deleteMessage;
    delete window.showMessage;

    $('#emailTable').DataTable().destroy();
}
