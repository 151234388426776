import Swup from 'swup';
import SwupFadeTheme from '@swup/fade-theme';
import SwupHeadPlugin from '@swup/head-plugin';
import SwupProgressPlugin from '@swup/progress-plugin';
import SwupScrollPlugin from '@swup/scroll-plugin';
import Popover from 'bootstrap/js/src/popover';
import feather from "feather-icons";
import {initDashboardPage, unloadDashboardPage} from "./app/dashboard";
import {initToolsListPage, unloadToolsListPage} from "./app/tools_list";
import {initPersmissionsPage, unloadPermissionsPage} from "./app/permissions";
import {initCronLogPage, unloadCronLogPage} from "./app/cron_log";
import {initUserLogPage, unloadUserLogPage} from "./app/user_log";
import {initSystemLogPage, unloadSystemLogPage} from "./app/system_log";
import {initEmailLogPage, unloadEmailLogPage} from "./app/email_log";
import {initDeliveryRecordsPage, unloadDeliveryRecordsPage} from "./app/delivery_records";
import {initStockPage, unloadStockPage} from "./app/stock";
import {initProductListPage, unloadProductListPage} from "./app/products";
import {initPartnersListPage, unloadPartnersListPage} from "./app/partners_list";
import {initRegularExpensesPage, unloadRegularExpensesPage} from "./app/regular_expenses";
import {initOneTimeExpensesPage, unloadOneTimeExpensesPage} from "./app/one_time_expenses";
import {initFaqPage, unloadFaqPage} from "./app/faq";
import {initManualPage, unloadManualPage} from "./app/manual";
import {initKanbanPage, unloadKanbanPage} from "./app/kanban";
import {initBlacklistedPage, unloadBlacklistPage} from "./app/blacklist";
import {initInventoryPage, unloadInventoryPage} from "./app/inventory_page";
import {initInventoryEntryPage, unloadInventoryEntryPage} from "./app/inventory_entry";
import {initIncomingInvoiceListPage, unloadIncomingInvoiceListPage} from "./app/incoming_invoice_list";
import {initContactPage, unloadContactPage} from "./app/contact";
import {initManageShopsPage, unloadManageShopsPage} from "./app/manage_shops";
import {initShopSettingsPage, unloadShopSettingsPage} from "./app/shop_settings";
import {initUsergroupSettingsPage, unloadUsergroupSettingsPage} from "./app/usergroup_settings";
import {initUsergroupMembersPage, unloadUsergroupMembersPage} from "./app/usergroup_members";
import {initProfilePage, unloadProfilePage} from "./app/profile";
import {initNotificationLogPage, unloadNotificationLogPage} from "./app/notifications_log";
import {initEmailTemplatesPage, unloadEmailTemplatesPage} from "./app/email_templates";
import {initStoredTemplatesPage, unloadStoredTemplatesPage} from "./app/stored_templates";
import {initCronjobPage, unloadCronjobPage} from "./app/cronjob";
import {initEmailDesignPage, unloadEmailDesignPage} from "./app/email_design";
import {initEmailServerPage, unloadEmailServerPage} from "./app/email_server";
import {initEmailSenderPage, unloadEmailSenderPage} from "./app/email_sender";
import {initProductCategoriesPage, unloadProductCategoriesPage} from "./app/product_categories";
import {initContainerLocationsPage, unloadContainerLocationsPage} from "./app/container_locations";
import {initProductContainersPage, unloadProductContainersPage} from "./app/product_containers";
import {initProductTypesPage, unloadProductTypesPage} from "./app/product_types";
import {initVatRatesPage, unloadVatRatesPage} from "./app/vat_rates";
import {initOrderStatusesPage, unloadOrderStatusesPage} from "./app/manage_order_statuses";
import {initManagePaymentModesPage, unloadManagePaymentModesPage} from "./app/manage_payment";
import {initManagePickupPointsPage, unloadManagePickupPointsPage} from "./app/manage_pickup_points";
import {initManageShippingModesPage, unloadManageShippingModesPage} from "./app/manage_shipping";
import {initAwaitingAcquistionPage, unloadAwaitingAcquistionPage} from "./app/awaiting_acquisition";
import {initAdminMonitoringPage, unloadAdminMonitoringPage} from "./admin/monitoring";
import {initFinancialReportPage, unloadFinancialReportPage} from "./app/financial_report";
import {initProductReportPage, unloadProductReportPage} from "./app/product_report";
import {initOrderReportPage, unloadOrderReportPage} from "./app/order_report";
import {initNewStockPage, unloadNewStockPage} from "./app/new_stock";
import {initEditProductPage, unloadEditProductPage} from "./app/edit_product";
import {initNewPartnerPage, unloadNewPartnerPage} from "./app/new_partner";
import {initEditPartnerPage, unloadEditPartnerPage} from "./app/edit_partner";
import {initMinimumStockPage, unloadMinimumStockPage} from "./app/minimum_stock";
import {initEditOrderPage, unloadEditOrderPage} from "./app/edit_order";
import {initBillingoDashboardPage, unloadBillingoDashboardPage} from "./modules/billingo_dashboard";
import {initBillingoSettingsPage, unloadBillingoSettingsPage} from "./modules/billingo_settings";
import {initGlsParcelsPage, unloadGlsParcelsPage} from "./modules/gls_parcels";
import {initGlsNewParcelPage, unloadGlsNewParcelPage} from "./modules/gls_new_parcel";
import {initGlsSettingsPage, unloadGlsSettingsPage} from "./modules/gls_settings";
import {initInvestorReportPage, unloadInvestorReportPage} from "./modules/investors_report";
import {initInvestorMembersPage, unloadInvestorMembersPage} from "./modules/investors_members";
import {initInvestorGroupsPage, unloadInvestorGroupsPage} from "./modules/investors_groups";
import {initFoxpostSettingsPage, unloadFoxpostSettingsPage} from "./modules/foxpost_settings";
import {initFoxpostNewPackagePage, unloadFoxpostNewPackagePage} from "./modules/foxpost_new_package";
import {initWholesaleFeedPage, unloadWholesaleFeedPage} from "./modules/wholesale_feed";
import {initInvoiceListPage, unloadInvoiceListPage} from "./app/invoice_list";
import {initInvoiceBasedInputPage, unloadInvoiceBasedInputPage} from "./app/invoice_based_input";
import {initXlsProcessorPage, unloadXlsProcessorPage} from "./modules/xls_processor";

let swup = new Swup({
    containers: ['#swup'],
    cache: true,
    animateHistoryBrowsing: true,
    linkSelector: 'a[data-swup-active]',
    animationSelector: '[class*="swup-transition-"]',
    plugins: [new SwupFadeTheme(), new SwupProgressPlugin(), new SwupHeadPlugin(), new SwupScrollPlugin({doScrollingRightAway: true})]
});

function initApp() {
    if (!window.location.href.includes('/app/')) {
        return;
    }

    if (document.querySelector('#awaiting-acquistion-page')) initAwaitingAcquistionPage();
    if (document.querySelector('#blacklist-page')) initBlacklistedPage();
    if (document.querySelector('#contact-page')) initContactPage();
    if (document.querySelector('#container-locations-page')) initContainerLocationsPage();
    if (document.querySelector('#cron-log-page')) initCronLogPage();
    if (document.querySelector('#cronjob-page')) initCronjobPage();
    if (document.querySelector('#dashboard-page')) initDashboardPage();
    if (document.querySelector('#delivery-records-page')) initDeliveryRecordsPage();
    if (document.querySelector('#edit-order-page')) initEditOrderPage();
    if (document.querySelector('#edit-partner-page')) initEditPartnerPage();
    if (document.querySelector('#email-design-page')) initEmailDesignPage();
    if (document.querySelector('#email-log-page')) initEmailLogPage();
    if (document.querySelector('#email-templates-page')) initEmailTemplatesPage();
    if (document.querySelector('#email-sender-page')) initEmailSenderPage();
    if (document.querySelector('#email-server-page')) initEmailServerPage();
    if (document.querySelector('#faq-page')) initFaqPage();
    if (document.querySelector('#financial-report-page')) initFinancialReportPage();
    if (document.querySelector('#incoming-invoice-list-page')) initIncomingInvoiceListPage();
    if (document.querySelector('#inventory-page')) initInventoryPage();
    if (document.querySelector('#inventory-entry-page')) initInventoryEntryPage();
    if (document.querySelector('#invoice-based-input-page')) initInvoiceBasedInputPage();
    if (document.querySelector('#invoice-list-page')) initInvoiceListPage();
    if (document.querySelector('#kanban-page')) initKanbanPage();
    if (document.querySelector('#manage-order-statuses-page')) initOrderStatusesPage();
    if (document.querySelector('#manage-payment-modes-page')) initManagePaymentModesPage();
    if (document.querySelector('#manage-pickup-points-page')) initManagePickupPointsPage();
    if (document.querySelector('#manage-shipping-modes-page')) initManageShippingModesPage();
    if (document.querySelector('#manage-shops-page')) initManageShopsPage();
    if (document.querySelector('#manual-page')) initManualPage();
    if (document.querySelector('#minimum-stock-page')) initMinimumStockPage();
    if (document.querySelector('#new-partner-page')) initNewPartnerPage();
    if (document.querySelector('#new-product-page')) initEditProductPage();
    if (document.querySelector('#new-stock-page')) initNewStockPage();
    if (document.querySelector('#notifications-log-page')) initNotificationLogPage();
    if (document.querySelector('#one-time-expenses-page')) initOneTimeExpensesPage();
    if (document.querySelector('#order-report-page')) initOrderReportPage();
    if (document.querySelector('#partners-list-page')) initPartnersListPage();
    if (document.querySelector('#permissions-page')) initPersmissionsPage();
    if (document.querySelector('#product-categories-page')) initProductCategoriesPage();
    if (document.querySelector('#product-containers-page')) initProductContainersPage();
    if (document.querySelector('#product-report-page')) initProductReportPage();
    if (document.querySelector('#product-types-page')) initProductTypesPage();
    if (document.querySelector('#products-list-page')) initProductListPage();
    if (document.querySelector('#profile-page')) initProfilePage();
    if (document.querySelector('#regular-expenses-page')) initRegularExpensesPage();
    if (document.querySelector('#shop-settings-page')) initShopSettingsPage();
    if (document.querySelector('#system-log-page')) initSystemLogPage();
    if (document.querySelector('#stored-templates-page')) initStoredTemplatesPage();
    if (document.querySelector('#tools-list-page')) initToolsListPage();
    if (document.querySelector('#user-log-page')) initUserLogPage();
    if (document.querySelector('#organisation-members-page')) initUsergroupMembersPage();
    if (document.querySelector('#usergroup-settings-page')) initUsergroupSettingsPage();
    if (document.querySelector('#stock-page')) initStockPage();
    if (document.querySelector('#vat-rates-page')) initVatRatesPage();
}

function initAdmin() {
    if (!window.location.href.includes('/admin/')) {
        return;
    }

    if (document.querySelector('#admin-monitoring-page')) initAdminMonitoringPage();
}

function initModules() {
    if (!window.location.href.includes('/modul/') && !window.location.href.includes('/module/')) {
        return;
    }

    if (document.querySelector('#billingo-dashboard-page')) initBillingoDashboardPage();
    if (document.querySelector('#billingo-settings-page')) initBillingoSettingsPage();
    if (document.querySelector('#foxpost-new-package-page')) initFoxpostNewPackagePage();
    if (document.querySelector('#foxpost-settings-page')) initFoxpostSettingsPage();
    if (document.querySelector('#gls-new-parcel-page')) initGlsNewParcelPage();
    if (document.querySelector('#gls-parcels-page')) initGlsParcelsPage();
    if (document.querySelector('#gls-settings-page')) initGlsSettingsPage();
    if (document.querySelector('#investor-groups-page')) initInvestorGroupsPage();
    if (document.querySelector('#investor-members-page')) initInvestorMembersPage();
    if (document.querySelector('#investor-report-page')) initInvestorReportPage();
    if (document.querySelector('#xls-processor-page')) initXlsProcessorPage();
    if (document.querySelector('#wholesale-feed-page')) initWholesaleFeedPage();
}

function unloadApp() {
    if (!window.location.href.includes('/app/')) {
        return;
    }

    if (document.querySelector('#awaiting-acquistion-page')) unloadAwaitingAcquistionPage();
    if (document.querySelector('#blacklist-page')) unloadBlacklistPage();
    if (document.querySelector('#contact-page')) unloadContactPage();
    if (document.querySelector('#container-locations-page')) unloadContainerLocationsPage();
    if (document.querySelector('#cron-log-page')) unloadCronLogPage();
    if (document.querySelector('#cronjob-page')) unloadCronjobPage();
    if (document.querySelector('#dashboard-page')) unloadDashboardPage();
    if (document.querySelector('#delivery-records-page')) unloadDeliveryRecordsPage();
    if (document.querySelector('#edit-order-page')) unloadEditOrderPage();
    if (document.querySelector('#edit-partner-page')) unloadEditPartnerPage();
    if (document.querySelector('#email-design-page')) unloadEmailDesignPage();
    if (document.querySelector('#email-log-page')) unloadEmailLogPage();
    if (document.querySelector('#email-templates-page')) unloadEmailTemplatesPage();
    if (document.querySelector('#email-sender-page')) unloadEmailSenderPage();
    if (document.querySelector('#email-server-page')) unloadEmailServerPage();
    if (document.querySelector('#faq-page')) unloadFaqPage();
    if (document.querySelector('#financial-report-page')) unloadFinancialReportPage();
    if (document.querySelector('#incoming-invoice-list-page')) unloadIncomingInvoiceListPage();
    if (document.querySelector('#inventory-page')) unloadInventoryPage();
    if (document.querySelector('#inventory-entry-page')) unloadInventoryEntryPage();
    if (document.querySelector('#invoice-based-input-page')) unloadInvoiceBasedInputPage();
    if (document.querySelector('#invoice-list-page')) unloadInvoiceListPage();
    if (document.querySelector('#kanban-page')) unloadKanbanPage();
    if (document.querySelector('#manage-order-statuses-page')) unloadOrderStatusesPage();
    if (document.querySelector('#manage-payment-modes-page')) unloadManagePaymentModesPage();
    if (document.querySelector('#manage-pickup-points-page')) unloadManagePickupPointsPage();
    if (document.querySelector('#manage-shipping-modes-page')) unloadManageShippingModesPage();
    if (document.querySelector('#manage-shops-page')) unloadManageShopsPage();
    if (document.querySelector('#manual-page')) unloadManualPage();
    if (document.querySelector('#minimum-stock-page')) unloadMinimumStockPage();
    if (document.querySelector('#new-partner-page')) unloadNewPartnerPage();
    if (document.querySelector('#new-product-page')) unloadEditProductPage();
    if (document.querySelector('#new-stock-page')) unloadNewStockPage();
    if (document.querySelector('#notifications-log-page')) unloadNotificationLogPage();
    if (document.querySelector('#one-time-expenses-page')) unloadOneTimeExpensesPage();
    if (document.querySelector('#order-report-page')) unloadOrderReportPage();
    if (document.querySelector('#partners-list-page')) unloadPartnersListPage();
    if (document.querySelector('#permissions-page')) unloadPermissionsPage();
    if (document.querySelector('#product-categories-page')) unloadProductCategoriesPage();
    if (document.querySelector('#product-containers-page')) unloadProductContainersPage();
    if (document.querySelector('#product-report-page')) unloadProductReportPage();
    if (document.querySelector('#product-types-page')) unloadProductTypesPage();
    if (document.querySelector('#products-list-page')) unloadProductListPage();
    if (document.querySelector('#profile-page')) unloadProfilePage();
    if (document.querySelector('#regular-expenses-page')) unloadRegularExpensesPage();
    if (document.querySelector('#shop-settings-page')) unloadShopSettingsPage();
    if (document.querySelector('#system-log-page')) unloadSystemLogPage();
    if (document.querySelector('#stored-templates-page')) unloadStoredTemplatesPage();
    if (document.querySelector('#tools-list-page')) unloadToolsListPage();
    if (document.querySelector('#user-log-page')) unloadUserLogPage();
    if (document.querySelector('#organisation-members-page')) unloadUsergroupMembersPage();
    if (document.querySelector('#usergroup-settings-page')) unloadUsergroupSettingsPage();
    if (document.querySelector('#stock-page')) unloadStockPage();
    if (document.querySelector('#vat-rates-page')) unloadVatRatesPage();
}

function unloadAdmin() {
    if (!window.location.href.includes('/admin/')) {
        return;
    }

    if (document.querySelector('#admin-monitoring-page')) unloadAdminMonitoringPage();
}

function unloadModules() {
    if (!window.location.href.includes('/modul/') && !window.location.href.includes('/module/')) {
        return;
    }

    if (document.querySelector('#billingo-dashboard-page')) unloadBillingoDashboardPage();
    if (document.querySelector('#billingo-settings-page')) unloadBillingoSettingsPage();
    if (document.querySelector('#foxpost-new-package-page')) unloadFoxpostNewPackagePage();
    if (document.querySelector('#foxpost-settings-page')) unloadFoxpostSettingsPage();
    if (document.querySelector('#gls-new-parcel-page')) unloadGlsNewParcelPage();
    if (document.querySelector('#gls-parcels-page')) unloadGlsParcelsPage();
    if (document.querySelector('#gls-settings-page')) unloadGlsSettingsPage();
    if (document.querySelector('#investor-groups-page')) unloadInvestorGroupsPage();
    if (document.querySelector('#investor-members-page')) unloadInvestorMembersPage();
    if (document.querySelector('#investor-report-page')) unloadInvestorReportPage();
    if (document.querySelector('#xls-processor-page')) unloadXlsProcessorPage();
    if (document.querySelector('#wholesale-feed-page')) unloadWholesaleFeedPage();
}

function loadHelpers() {
    let popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));

    popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl, {});
    });

    feather.replace({
        width: 14,
        height: 14
    });
}

swup.hooks.on('content:replace', function () {
    $('a[href="' + location.pathname + '"]').parent().addClass('active');
    loadHelpers();

    initApp();
    initAdmin();
    initModules();
});

swup.hooks.on('history:popstate', function () {
    unloadApp();
    unloadAdmin();
    unloadModules();
});

swup.hooks.before('content:replace', function () {
    unloadApp();
    unloadAdmin();
    unloadModules();
});

swup.hooks.on('visit:start', function () {
    $('#main-menu-navigation .active').fadeOut("slow", function () {
        $(this).removeClass("active");
        $(this).css('display', 'block');
    });
});

initApp();
initAdmin();
initModules();
loadHelpers();

$('a[data-swup-active]').on('click', function (e) {
    e.preventDefault();

    swup.navigate($(this).prop('href'));
});
