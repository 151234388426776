import '../../app/vendors/css/editors/quill/quill.snow.css';
import '../../app/css/plugins/forms/form-quill-editor.css';
import Quill from 'quill';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onEmailShopChange_51c7de84() {
    let emailHelper = $('#email_helper');
    let textmode = $("#text-mode-email").val();
    let action = $("#text-action-email").val();
    let shop = $("#shop-email").val();

    if ($.inArray(null, [textmode, action, shop]) < 0) {
        $('#full-container-email .editor').data('quill').enable(true);
        $("#stored-subject-email").prop("disabled", false);
    } else {
        return;
    }

    $.ajax({
        url: emailHelper.data('value'),
        type: "post",
        async: true,
        data: {
            "name": action,
            "mode": textmode,
            "shop": shop
        }
    }).done(function (response) {
        if (response.success) {
            $('#full-container-email .editor').data('quill').clipboard.dangerouslyPasteHTML(response.data.body);
            $("#stored-subject-email").val(response.data.subject);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonET_51c7de84(event) {
    toggleLoading('#saveButtonET', '#loadingButtonET');
    event.preventDefault();
    let emailHelper = $('#email_helper');
    let form = $('#email-templates-form');
    let fd = new FormData(form[0]);
    let url = emailHelper.data('edit');
    let inputs = $(document).find("input, select, button, textarea");

    inputs.prop("disabled", true);
    fd.append('text', $('#email-templates-form .ql-editor').html());

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonET', '#loadingButtonET');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initEmailTemplatesPage() {
    window.Quill = Quill;

    let quill = new Quill('#full-container-email .editor', {
        bounds: '#full-container-email .editor',
        modules: {
            toolbar: [
                [{font: []}, {size: []}],
                ['bold', 'italic', 'underline', 'strike'],
                [{color: []}, {background: []}],
                [{script: 'super'}, {script: 'sub'}],
                [{header: '1'}, {header: '2'}, 'blockquote', 'code-block'],
                [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
                ['direction', {align: []}],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ]
        },
        theme: 'snow'
    });

    quill.enable(false);
    $('#full-container-email .editor').data('quill', quill);

    $("#saveButtonET").on("click", onSaveButtonET_51c7de84);
    $("#text-action-email, #text-mode-email, #shop-email").on('change', onEmailShopChange_51c7de84);
}

export function unloadEmailTemplatesPage() {
    delete window.Quill;

    $("#saveButtonET").off("click", onSaveButtonET_51c7de84);
    $("#text-action-email, #text-mode-email, #shop-email").off('change', onEmailShopChange_51c7de84);
}
