import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonNCL_19c1e052(event) {
    let form = $("#new-container-location-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSaveButtonCCL_19c1e052(event) {
    let form = $("#change-container-location-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewContainerLocationForm_19c1e052(event) {
    let locations = $('#locations-helper');
    toggleLoading('#saveButtonNCL', '#loadingButtonNCL');
    event.preventDefault();
    let form = $(this);
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = locations.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showContainerLocations();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNCL', '#loadingButtonNCL');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onChangeContainerLocationForm_19c1e052(event) {
    let locations = $('#locations-helper');
    toggleLoading('#saveButtonCCL', '#loadingButtonCCL');
    event.preventDefault();
    let form = $(this);
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = locations.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showContainerLocations();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCCL', '#loadingButtonCCL');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initContainerLocationsPage() {
    let locations = $('#locations-helper');

    window.showContainerLocations = function () {
        $.ajax({
            url: locations.data('div'),
            type: 'post',
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#container-locations-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeContainerLocation = function (id) {
        $.ajax({
            url: locations.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-location-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteContainerLocation = function (id, name) {
        deleteDialog(id, decodeURI(name), locations.data('delete-confirm'), locations.data('delete'), locations.data('delete-url'), () => {
            showContainerLocations();
        });
    };

    window.viewLocations = function (id) {
        $.ajax({
            url: locations.data('containers'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#view-containers-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    showContainerLocations();

    $("#saveButtonNCL").on("click", onSaveButtonNCL_19c1e052);
    $("#saveButtonCCL").on("click", onSaveButtonCCL_19c1e052);

    $("#new-container-location-form").on("submit", onNewContainerLocationForm_19c1e052);
    $("#change-container-location-form").on("submit", onChangeContainerLocationForm_19c1e052);
}

export function unloadContainerLocationsPage() {
    delete window.showContainerLocations;
    delete window.changeContainerLocation;
    delete window.deleteContainerLocation;
    delete window.viewLocations;

    $("#saveButtonNCL").off("click", onSaveButtonNCL_19c1e052);
    $("#saveButtonCCL").off("click", onSaveButtonCCL_19c1e052);

    $("#new-container-location-form").off("submit", onNewContainerLocationForm_19c1e052);
    $("#change-container-location-form").off("submit", onChangeContainerLocationForm_19c1e052);
}
