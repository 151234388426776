import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function onSaveButtonNCO_acf399ee(event) {
    let form = $("#new-product-container-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewContainerForm_acf399ee(event) {
    toggleLoading('#saveButtonNCO', '#loadingButtonNCO');
    event.preventDefault();
    let form = $(this);
    let containers = $('#containers-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = containers.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showContainerTypes();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNCO', '#loadingButtonNCO');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCCO_acf399ee(event) {
    let form = $("#change-product-container-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeContainerForm_acf399ee(event) {
    toggleLoading('#saveButtonCCO', '#loadingButtonCCO');
    event.preventDefault();
    let form = $(this);
    let containers = $('#containers-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = containers.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showContainerTypes();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCCO', '#loadingButtonCCO');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initProductContainersPage() {
    let containers = $('#containers-helper');

    window.showContainerTypes = function () {
        $.ajax({
            url: containers.data('div'),
            type: 'post',
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#container-types-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeProductContainer = function (id) {
        $.ajax({
            url: containers.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-product-container-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteProductContainer = function (id, name) {
        deleteDialog(id, decodeURI(name), containers.data('delete-confirm'), containers.data('delete'), containers.data('delete-url'), () => {
            showContainerTypes();
        });
    };

    showContainerTypes();

    $("#saveButtonNCO").on("click", onSaveButtonNCO_acf399ee);
    $("#new-product-container-form").on("submit", onNewContainerForm_acf399ee);
    $("#saveButtonCCO").on("click", onSaveButtonCCO_acf399ee);
    $("#change-product-container-form").on("submit", onChangeContainerForm_acf399ee);
}

export function unloadProductContainersPage() {
    delete window.showContainerTypes;
    delete window.changeProductContainer;
    delete window.deleteProductContainer;

    $("#saveButtonNCO").off("click", onSaveButtonNCO_acf399ee);
    $("#new-product-container-form").off("submit", onNewContainerForm_acf399ee);
    $("#saveButtonCCO").off("click", onSaveButtonCCO_acf399ee);
    $("#change-product-container-form").off("submit", onChangeContainerForm_acf399ee);
}
