import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import ApexCharts from 'apexcharts';
import feather from "feather-icons";
import {swalUnknown} from "../functions";
import Tooltip from "bootstrap/js/src/tooltip";

function onInvestorGroupChange_8adefa02() {
    let group = $('#group').val();
    let year = $('#year').val();

    updateBarChart(group, year);
    updateAreaChart(group, year);
    updateComissionTable(group, year);
}

function onInvestorYearChange_8adefa02() {
    let group = $('#group').val();
    let year = $('#year').val();

    updateAreaChart(group, year);
    updateBarChart(group, year);
    updateComissionTable(group, year);
}

export function initInvestorReportPage() {
    let helper = $('#helper');
    let barChartElement, areaChartElement;
    let group = $('#group');
    let year = $('#year');

    window.ApexCharts = ApexCharts;

    window.areaChart = function (data) {
        let options = {
            series: data,
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                height: 350,
                type: 'area'
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                categories: [
                    helper.data('jan'), helper.data('feb'), helper.data('mar'), helper.data('apr'),
                    helper.data('may'), helper.data('jun'), helper.data('jul'), helper.data('aug'),
                    helper.data('sep'), helper.data('oct'), helper.data('nov'), helper.data('dec')
                ]
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        let value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        return value + " " + helper.data('currency');
                    }
                }
            },
        };

        if (typeof areaChartElement !== 'undefined') {
            areaChartElement.destroy();
        }

        areaChartElement = new ApexCharts(document.querySelector("#areaChart"), options);
        areaChartElement.render().then();

        $('#areaChart').data('chart', areaChartElement);
    };

    window.updateAreaChart = function (group, year) {
        if (group === null) {
            return;
        }

        $.ajax({
            url: helper.data('ipy'),
            type: "post",
            data: {
                "group": group,
                "year": year
            },
            async: true
        }).done(function (response) {
            if (response.success) {
                let data = [];
                $.each(response.data, function (i, v) {
                    data.push({
                        name: v.name,
                        data: Object.values(v.data)
                    });
                });
                areaChart(data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.barChart = function (labels, sold, total) {
        let options = {
            series: [{
                name: helper.data('sold'),
                data: sold
            }, {
                name: helper.data('total'),
                data: total
            }],
            chart: {
                toolbar: {
                    show: false
                },
                type: 'bar',
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: labels,
            },
            fill: {
                opacity: 1
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        let value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        return value + " " + helper.data('currency');
                    }
                }
            }
        };

        if (typeof barChartElement !== 'undefined') {
            barChartElement.destroy();
        }

        barChartElement = new ApexCharts(document.querySelector("#barChart"), options);
        barChartElement.render().then();

        $('#barChart').data('chart', barChartElement);
    };

    window.updateBarChart = function (group, year) {
        if (group === null) {
            return;
        }

        $.ajax({
            url: helper.data('cpm'),
            type: "post",
            data: {
                "group": group,
                "year": year
            },
            async: true
        }).done(function (response) {
            if (response.success) {
                let labels = [], sold = [], total = [];
                $.each(response.data, function (i, v) {
                    labels.push(v.name);
                    sold.push(v.sold);
                    total.push(v.total);
                });
                barChart(labels, sold, total);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.updateComissionTable = function (group, year) {
        if (group === null) {
            return;
        }

        $.ajax({
            url: helper.data('mcpm'),
            type: "post",
            data: {
                "group": group,
                "year": year
            },
            async: true
        }).done(function (response) {
            if (response.success) {
                $('#mcpm_table').html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });

                let tooltipTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

                tooltipTriggerList.map(function (tooltipTriggerEl) {
                    return new Tooltip(tooltipTriggerEl);
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    areaChart([]);
    barChart([], [], []);

    updateBarChart(group.val(), year.val());
    updateAreaChart(group.val(), year.val());

    group.on('change', onInvestorGroupChange_8adefa02);
    year.on('change', onInvestorYearChange_8adefa02);
}

export function unloadInvestorReportPage() {
    delete window.ApexCharts;
    delete window.updateComissionTable;
    delete window.updateBarChart;
    delete window.barChart;
    delete window.updateAreaChart;
    delete window.areaChart;

    let areaChart = $('#areaChart');
    let barChart = $('#barChart');

    $('#group').off('change', onInvestorGroupChange_8adefa02);
    $('#year').off('change', onInvestorYearChange_8adefa02);

    if (typeof areaChart.data('chart') !== 'undefined') {
        areaChart.data('chart').destroy();
    }

    if (typeof barChart.data('chart') !== 'undefined') {
        barChart.data('chart').destroy();
    }
}
