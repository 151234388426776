import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import '../../app/css/pages/app-invoice.css';
import '../../app/css/pages/page-profile.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';
import ApexCharts from 'apexcharts';
import repeater from 'jquery.repeater';
import flatpickr from "flatpickr";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import Popover from "bootstrap/js/src/popover";

function onBackorderChange_1f434579() {
    if ($('#backorder').is(':checked')) {
        $('.backorder-price-div').slideDown();
    } else {
        $('.backorder-price-div').slideUp();
    }
}

function onSaveButton_1f434579(event) {
    let form = $("#product-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewProductForm_1f434579(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();

    let url;
    let form = $(this);
    let helper = $('#helper');
    let fd = new FormData(form[0]);
    let inputs = $(this).find("input, select, button, textarea");

    if (helper.data('edit') === 1) {
        url = helper.data('edit-url');
    } else {
        url = helper.data('add-url');
    }

    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            form.removeClass("was-validated");

            if ($('#helper').data('edit') !== 1) {
                form.trigger("reset");
                $('.backorder-price-div').slideUp();
            }
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onGrossPriceChange_1f434579(event) {
    let vat = $('#category option:selected').data('vat') / 100 + 1;
    let digits = $('#digits').val();
    let gross = $(event.target);
    let net = gross.parents('.d-flex, .backorder-prices').find('.net-price');
    let value = gross.val() / vat;

    net.val(value.toFixed(digits));
}

function onNetPriceChange_1f434579(event) {
    let vat = $('#category option:selected').data('vat') / 100 + 1;
    let digits = $('#digits').val();
    let net = $(event.target);
    let gross = net.parents('.d-flex, .backorder-prices').find('.gross-price');
    let value = net.val() * vat;

    gross.val(value.toFixed(digits));
}

function onCategoryChange_1f434579() {
    $('.vat_rate_info').text($('#category option:selected').data('vat'));
    $('.net-price').trigger('input');
    $('.gross-price').trigger('input');
}

function onCopyBarcode_1f434579(event) {
    $(event.target).parents('.input-group').find('.form-control').val($('#barcode').val());
}

function onCopyReference_1f434579(event) {
    $(event.target).parents('.input-group').find('.form-control').val($('#wholesale_reference').val());
}

export function initEditProductPage() {
    window.ApexCharts = ApexCharts;
    window.flatpickr = flatpickr;
    window.repeater = repeater;

    let helper = $('#helper');

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    $('#upload_date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    $('.repeater').repeater({
        show: function () {
            $(this).slideDown();

            let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

            popoverTriggerList.map(function (popoverTriggerEl) {
                return new Popover(popoverTriggerEl, {});
            });

            if (feather) {
                feather.replace({width: 14, height: 14});
            }
        },
        hide: function (deleteElement) {
            if (confirm(helper.data('confirm'))) {
                $(this).slideUp(deleteElement);
            }
        }
    });

    let popoverTriggerList = [].slice.call(document.querySelectorAll('.has-tooltip'));

    popoverTriggerList.map(function (popoverTriggerEl) {
        return new Popover(popoverTriggerEl, {});
    });

    $('.vat_rate_info').text($('#category option:selected').data('vat'));

    if (helper.data('edit') === 1) {
        $.ajax({
            url: helper.data('stats'),
            data: 'ID=' + $('#product_id').val(),
            type: "post",
            async: true
        }).done(function (response) {
            let data = response;
            let salesChart;
            let salesChartOptions = {
                chart: {
                    height: 300,
                    toolbar: {show: false},
                    zoom: {enabled: false},
                    type: 'line',
                    offsetX: -10,
                    redrawOnParentResize: true
                },
                stroke: {
                    curve: 'smooth',
                    dashArray: [0, 12],
                    width: [4, 3]
                },
                legend: {
                    show: true
                },
                colors: ['#d0ccff', '#ebe9f1'],
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        inverseColors: false,
                        gradientToColors: [window.colors.solid.primary, '#ebe9f1'],
                        shadeIntensity: 1,
                        type: 'horizontal',
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 100, 100, 100]
                    }
                },
                markers: {
                    size: 0,
                    hover: {
                        size: 5
                    }
                },
                xaxis: {
                    labels: {
                        style: {
                            colors: '#b9b9c3',
                            fontSize: '1rem'
                        }
                    },
                    axisTicks: {
                        show: false
                    },
                    categories: data.months,
                    axisBorder: {
                        show: false
                    },
                    tickPlacement: 'on'
                },
                yaxis: {
                    tickAmount: 5,
                    labels: {
                        style: {
                            colors: '#b9b9c3',
                            fontSize: '1rem'
                        },
                        formatter: function (val) {
                            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                        }
                    }
                },
                grid: {
                    borderColor: '#e7eef7',
                    padding: {
                        top: -20,
                        bottom: -10,
                        left: 20
                    }
                },
                tooltip: {
                    x: {show: false}
                },
                series: [
                    {
                        name: helper.data('this-year'),
                        data: data.current
                    },
                    {
                        name: helper.data('last-year'),
                        data: data.last
                    }
                ]
            };

            salesChart = new ApexCharts(document.querySelector('#sales-chart'), salesChartOptions);
            salesChart.render().then();

            $('#sales-chart').data('chart', salesChart);
        });

        $.ajax({
            url: helper.data('images'),
            data: 'ID=' + $('#product_id').val(),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $('#images-section').html(response.data);
            }
        });
    }

    $('#backorder').on('change', onBackorderChange_1f434579);
    $("#saveButton").on("click", onSaveButton_1f434579);
    $("#product-form").on('submit', onNewProductForm_1f434579);

    $(document).on('change', '#category', onCategoryChange_1f434579);
    $(document).on('input', '.net-price', onNetPriceChange_1f434579);
    $(document).on('input', '.gross-price', onGrossPriceChange_1f434579);
    $(document).on('click', '.copy-reference', onCopyReference_1f434579);
    $(document).on('click', '.copy-barcode', onCopyBarcode_1f434579);
}

export function unloadEditProductPage() {
    delete window.ApexCharts;
    delete window.flatpickr;
    delete window.repeater;

    let chart = $('#sales-chart');
    $('.flatpickr-calendar').remove();

    if (typeof chart.data('chart') !== 'undefined') {
        chart.data('chart').destroy();
    }

    $('#backorder').off('change', onBackorderChange_1f434579);
    $("#saveButton").off("click", onSaveButton_1f434579);
    $("#product-form").off('submit', onNewProductForm_1f434579);

    $(document).on('change', '#category', onCategoryChange_1f434579);
    $(document).off('input', '.net-price', onNetPriceChange_1f434579);
    $(document).off('input', '.gross-price', onGrossPriceChange_1f434579);
    $(document).off('click', '.copy-reference', onCopyReference_1f434579);
    $(document).off('click', '.copy-barcode', onCopyBarcode_1f434579);
}
