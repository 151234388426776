import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import ApexCharts from 'apexcharts';

export function initOrderReportPage() {
    window.ApexCharts = ApexCharts;

    let helper = $('#helper');
    let day = ' ' + helper.data('day');

    let statusChart = new ApexCharts(document.querySelector("#status-comparison"), {
        series: [helper.data('completed-percent'), helper.data('draft-percent'), helper.data('cancelled-percent')],
        chart: {
            width: 380,
            type: 'pie',
        },
        colors: ['#28C76F', '#FF9F43', '#EA5455'],
        labels: [helper.data('completed'), helper.data('pending'), helper.data('cancelled')],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    });

    statusChart.render().then();
    $('#status-comparison').data('chart', statusChart);

    $.ajax({
        url: helper.data('order-rows'),
        type: "post",
        async: true
    }).done(function (response) {
        let processingChart = new ApexCharts(document.querySelector("#average-processing-flot"), {
            series: [{
                name: helper.data('orders'),
                data: response
            }],
            chart: {
                zoom: {
                    enabled: false
                },
                toolbar: {
                    show: false
                },
                height: 350,
                type: 'bar',
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top',
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: [0 + day, 1 + day, 2 + day, 3 + day, 4 + day, 5 + day, 6 + day, 7 + day, 8 + day, 9 + day, 10 + day],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false,
                },
                labels: {
                    show: false,
                }

            },
        });

        processingChart.render().then();
        $('#average-processing-flot').data('chart', processingChart);
    });
}

export function unloadOrderReportPage() {
    delete window.ApexCharts;

    let statusComparison = $('#status-comparison');
    let averageProcessing = $('#average-processing-flot');

    if (typeof statusComparison.data('chart') !== 'undefined') {
        statusComparison.data('chart').destroy();
    }

    if (typeof averageProcessing.data('chart') !== 'undefined') {
        averageProcessing.data('chart').destroy();
    }
}
