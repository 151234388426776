import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';
import '../../app/vendors/css/forms/select/select2.min.css';
import flatpickr from "flatpickr";
import select2 from 'select2';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButton_f88b10eb(event) {
    let form = $("#new-product-to-stock-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewStockForm_f88b10eb(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let productId = $("#product_id");
    let fd = new FormData(form[0]);
    let url = helper.data('add');
    let inputs = $(this).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            form.trigger("reset");
            productId.val(null).trigger('change');
            form.removeClass("was-validated");
            $("#info").addClass('d-none');
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onProductSelect_f88b10eb(e) {
    let data = e.params.data;
    let container = data.container_num;

    $("#info").removeClass("d-none");
    $('#info_product').html(data.product_name + ' (' + data.SKU + ')');
    $('#info_stock').html(data.on_stock);
    $('#info_avg_price').html(data.avg_gross_price);
    $('#info_container').html(data.container_info);
    $('#barcode').val(data.barcode);
    $('#vat_rate').val(data.vat_id);

    if (container !== null && (container.length > 0 || container > 0)) {
        $("#container").val(container);
    }
}

function onInvestorChange_f88b10eb() {
    let helper = $('#helper');
    let investor = $('#investor_id');

    investor.prop('disabled', false);
    investor.find('option').remove();

    $.ajax({
        url: helper.data('investors-list'),
        type: 'POST',
        data: {"ID": $('#investor_group').val()},
        async: true
    }).done(function (response) {
        if (response.success) {
            $.each(response.data, function (key, value) {
                investor.append($("<option></option>").attr("value", key).text(value));
            });
        }
    });
}

function onGrossPrice_f88b10eb(event) {
    let current = parseFloat($(event.target).val());
    let vat = parseFloat($('#vat_rate option:selected').data('rate')) / 100 + 1;

    $('#net_price').val((current / vat).toFixed($('#currency option:selected').data('digits')));
}

function onNetPrice_f88b10eb(event) {
    let current = parseFloat($(event.target).val());
    let vat = parseFloat($('#vat_rate option:selected').data('rate')) / 100 + 1;

    $('#gross_price').val((current * vat).toFixed($('#currency option:selected').data('digits')));
}

function onVatRate_f88b10eb() {
    let rate = parseFloat($('#vat_rate option:selected').data('rate'));

    $('#net_price').val((parseFloat($('#gross_price').val()) / (rate / 100 + 1)).toFixed($('#currency option:selected').data('digits')));
}

export function initNewStockPage() {
    let html = $('html');
    let helper = $('#helper');
    let productId = $("#product_id");

    window.flatpickr = flatpickr;
    window.select2 = select2;

    if (html.attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    window.formatProduct = function (product) {
        if (product.loading) {
            return product.text;
        }

        return "<div class='select2-result-repository clearfix d-flex'>" +
            "<div class='select2-result-repository__avatar mr-2'>" +
            "<img src='" + helper.data('assets') + "/img/product/" + product.image_url + "' class='width-2 height-2 mt-1 rounded' alt='product_image' />" +
            "</div>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title fs-lg fw-500'>" + product.product_name + "</div>" +
            "<div class='select2-result-repository__statistics d-flex font-small-3'>" +
            "<div class='select2-result-repository__forks mr-2'><b>" + helper.data('sku') + ":</b> " + product.SKU + "</div>" +
            "<div class='select2-result-repository__stargazers mr-2'><b>" + helper.data('price') + ":</b> " + product.avg_gross_price + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('container') + ":</b> " + product.container_info + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('stock') + ":</b> " + product.on_stock + " " + helper.data('abbr') + "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatProductSelection = function (product) {
        return product.product_name || product.text;
    };

    window.formatPartner = function (product) {
        if (product.loading) {
            return product.text;
        }

        return "<div class='select2-result-repository clearfix d-flex'>" +
            "<div class='select2-result-repository__meta'>" +
            "<div class='select2-result-repository__title fs-lg fw-500'>" + product.name + "</div>" +
            "<div class='select2-result-repository__statistics d-flex font-small-3'>" +
            "<div class='select2-result-repository__forks mr-2'><b>" + helper.data('vat') + ":</b> " + product.vat + "</div>" +
            "<div class='select2-result-repository__stargazers mr-2'><b>" + helper.data('country') + ":</b> " + product.country + "</div>" +
            "<div class='select2-result-repository__watchers mr-2'><b>" + helper.data('city') + ":</b> " + product.city + "</div>" +
            "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatPartnerSelection = function (product) {
        return product.name || product.text;
    };

    $('#upload_date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    $('#warranty').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    productId.select2({
        ajax: {
            url: helper.data('products'),
            dataType: 'json',
            delay: 1000,
            cache: true,
            type: 'post',
            async: true,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatProduct,
        templateSelection: formatProductSelection
    });

    $('#partner_id').select2({
        ajax: {
            url: helper.data('partners-list'),
            dataType: 'json',
            delay: 1000,
            cache: true,
            type: 'post',
            async: true,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select-option'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatPartner,
        templateSelection: formatPartnerSelection
    });

    $("#saveButton").on("click", onSaveButton_f88b10eb);
    $("#new-product-to-stock-form").on('submit', onNewStockForm_f88b10eb);
    productId.on("select2:select", onProductSelect_f88b10eb);

    if (helper.data('investors') === 1) {
        $('#investor_group').on('change', onInvestorChange_f88b10eb);
    }

    $("#gross_price").on("input", onGrossPrice_f88b10eb);
    $("#net_price").on("input", onNetPrice_f88b10eb);
    $("#vat_rate").on("change", onVatRate_f88b10eb);
}

export function unloadNewStockPage() {
    delete window.flatpickr;
    delete window.select2;
    delete window.formatProduct;
    delete window.formatProductSelection;
    delete window.formatPartner;
    delete window.formatPartnerSelection;

    let productId = $('#product_id');

    productId.select2('destroy');
    $('#partner_id').select2('destroy');
    $('.flatpickr-calendar').remove();

    productId.off("select2:select", onProductSelect_f88b10eb);
    $("#saveButton").off("click", onSaveButton_f88b10eb);
    $("#new-product-to-stock-form").off('submit', onNewStockForm_f88b10eb);

    if ($('#helper').data('investors') === 1) {
        $('#investor_group').off('change', onInvestorChange_f88b10eb);
    }

    $("#gross_price").off("input", onGrossPrice_f88b10eb);
    $("#net_price").off("input", onNetPrice_f88b10eb);
    $("#vat_rate").off("change", onVatRate_f88b10eb);
}
