import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButton_6f088d4c(event) {
    let form = $("#edit-partner-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSubmitForm_6f088d4c(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let fd = new FormData(form[0]);
    let url = $('#helper').data('url');
    let inputs = $(this).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
            form.removeClass("was-validated");
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initEditPartnerPage() {
    let wholesaleHelper = $('#wholesale-helper');

    if ($('#helper').data('feed') === 1) {
        window.showWholesaleFeeds = function () {
            $.ajax({
                url: wholesaleHelper.data('div'),
                type: "post",
                async: true,
                data: 'ID=' + $('#partner').val()
            }).done(function (response) {
                if (response.success) {
                    $("#xml-feed-tab").html(response.data);

                    feather.replace({
                        width: 14,
                        height: 14
                    });
                }
            }).fail(function (e) {
                swalUnknown(e);
            });
        };

        window.deleteFeed = function (id, name) {
            deleteDialog(id, decodeURI(name), wholesaleHelper.data('confirm'), wholesaleHelper.data('delete'), wholesaleHelper.data('url'), () => {
                showWholesaleFeeds();
            });
        };

        showWholesaleFeeds();
    }

    $("#saveButton").on("click", onSaveButton_6f088d4c);
    $("#edit-partner-form").on('submit', onSubmitForm_6f088d4c);
}

export function unloadEditPartnerPage() {
    if ($('#helper').data('feed') === 1) {
        delete window.deleteFeed;
        delete window.showWholesaleFeeds;
    }

    $("#saveButton").off("click", onSaveButton_6f088d4c);
    $("#edit-partner-form").off('submit', onSubmitForm_6f088d4c);
}
