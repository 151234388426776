import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import ApexCharts from 'apexcharts';

function updateMailQueue_5624369c(failed, sent) {
    let mailQueueChart;
    let options = {
        series: [{
            name: 'Sent',
            data: sent
        }, {
            name: 'Failed',
            data: failed
        }],
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            min: new Date(sent[0][0]).getTime()

        },
        tooltip: {
            x: {
                format: 'HH:mm'
            },
        },
    };

    if (typeof mailQueueChart !== 'undefined') {
        mailQueueChart.destroy();
    }

    mailQueueChart = new ApexCharts(document.querySelector("#mail-queue"), options);
    mailQueueChart.render().then();
    $('#queue').data('chart', mailQueueChart);
}

function updateMailUsage_5624369c(sent, unsent, failed) {
    let mailUsageChart;
    let options = {
        series: [{
            name: 'Sent',
            data: sent
        }, {
            name: 'Unsent',
            data: unsent
        }, {
            name: 'Failed',
            data: failed
        }],
        chart: {
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            },
            height: 350,
            type: 'area'
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth'
        },
        xaxis: {
            type: 'datetime',
            min: new Date(sent[0][0]).getTime()

        },
        tooltip: {
            x: {
                format: 'HH:mm'
            },
        },
    };

    if (typeof mailUsageChart !== 'undefined') {
        mailUsageChart.destroy();
    }

    mailUsageChart = new ApexCharts(document.querySelector("#mail-usage"), options);
    mailUsageChart.render().then();
    $('#mail').data('chart', mailUsageChart);
}

function updateDiskUsage_5624369c(used, free) {
    let disk = $('#disk');
    let diskUsageChart;
    let options = {
        series: [used, free],
        chart: {
            width: 380,
            type: 'pie',
        },
        labels: [disk.data('used'), disk.data('free')],
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom'
                }
            }
        }],
        tooltip: {
            y: {
                formatter: function (val) {
                    let value = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                    return value + " MB";
                }
            }
        },
    };

    if (typeof diskUsageChart !== 'undefined') {
        diskUsageChart.destroy();
    }

    diskUsageChart = new ApexCharts(document.querySelector("#disk-usage"), options);
    diskUsageChart.render().then();
    disk.data('chart', diskUsageChart);
}

function updateDiskUsageInterval_5624369c() {
    let url = $('#disk').data('url');

    $.ajax({
        url: url,
        type: "post",
        async: true
    }).done(function (response) {
        if (response.success) {
            updateDiskUsage_5624369c(response.data.used, response.data.free);
            $('#disk-usage-update i').html(response.updated);
        }
    });
}

function updateMailUsageInterval_5624369c() {
    let url = $('#mail').data('url');

    $.ajax({
        url: url,
        type: "post",
        async: true
    }).done(function (response) {
        if (response.success) {
            let sent = [], unsent = [], failed = [];

            for (let key in response.data.sent) {
                if (response.data.sent.hasOwnProperty(key))
                    sent.push([parseInt(key + '000'), response.data.sent[key]]);
            }

            for (let key in response.data.unsent) {
                if (response.data.unsent.hasOwnProperty(key))
                    unsent.push([parseInt(key + '000'), response.data.unsent[key]]);
            }

            for (let key in response.data.failed) {
                if (response.data.failed.hasOwnProperty(key))
                    failed.push([parseInt(key + '000'), response.data.failed[key]]);
            }

            updateMailUsage_5624369c(sent, unsent, failed);
            $('#mail-usage-updated i').html(response.updated);
        }
    });
}

function updateMailQueueInterval_5624369c() {
    let url = $('#queue').data('url');

    $.ajax({
        url: url,
        type: "post",
        async: true
    }).done(function (response) {
        if (response.success) {
            let sent = [], failed = [];

            for (let key in response.data.sent) {
                if (response.data.sent.hasOwnProperty(key))
                    sent.push([parseInt(key + '000'), response.data.sent[key]]);
            }

            for (let key in response.data.failed) {
                if (response.data.failed.hasOwnProperty(key))
                    failed.push([parseInt(key + '000'), response.data.failed[key]]);
            }

            updateMailQueue_5624369c(failed, sent);
            $('#mail-queue-updated i').html(response.updated);
        }
    });
}

export function initAdminMonitoringPage() {
    let disk = $('#disk');

    window.ApexCharts = ApexCharts;

    updateDiskUsage_5624369c(disk.data('used-space'), disk.data('free-space'));

    setInterval(updateDiskUsageInterval_5624369c, 60000);
    setInterval(updateMailUsageInterval_5624369c, 30000);
    setInterval(updateMailQueueInterval_5624369c, 20000);
}

export function unloadAdminMonitoringPage() {
    delete window.ApexCharts;

    let disk = $('#disk');
    let queue = $('#queue');
    let mail = $('#mail');

    clearInterval(updateDiskUsageInterval_5624369c);
    clearInterval(updateMailUsageInterval_5624369c);
    clearInterval(updateMailQueueInterval_5624369c);

    if (typeof disk.data('chart') !== 'undefined') {
        disk.data('chart').destroy();
    }

    if (typeof queue.data('chart') !== 'undefined') {
        queue.data('chart').destroy();
    }

    if (typeof mail.data('chart') !== 'undefined') {
        mail.data('chart').destroy();
    }
}
