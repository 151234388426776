import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonNVR_69419f0e(event) {
    let form = $("#new-vat-rate-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewVatRateForm_69419f0e(event) {
    toggleLoading('#saveButtonNVR', '#loadingButtonNVR');
    event.preventDefault();
    let form = $(this);
    let vat = $('#vat-rates-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = vat.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showVatRates();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNVR', '#loadingButtonNVR');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCVR_69419f0e(event) {
    let form = $("#change-vat-rate-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeVatRateForm_69419f0e(event) {
    toggleLoading('#saveButtonCVR', '#loadingButtonCVR');
    event.preventDefault();
    let form = $(this);
    let vat = $('#vat-rates-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = vat.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showVatRates();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCVR', '#loadingButtonCVR');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onChangeVatType_69419f0e() {
    let fd = new FormData();
    let url = $('#vat-rates-helper').data('type');

    fd.append('type', $('input[name="vat_type"]:checked').val());

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initVatRatesPage() {
    let vat = $('#vat-rates-helper');

    window.showVatRates = function () {
        $.ajax({
            url: vat.data('div'),
            type: 'post',
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#vat-rates-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeVatRate = function (id) {
        $.ajax({
            url: vat.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-vat-rate-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteVatRate = function (id, name) {
        deleteDialog(id, decodeURI(name), vat.data('delete-confirm'), vat.data('delete'), vat.data('delete-url'), () => {
            showVatRates();
        });
    };

    showVatRates();

    $("#saveButtonNVR").on("click", onSaveButtonNVR_69419f0e);
    $("#new-vat-rate-form").on("submit", onNewVatRateForm_69419f0e);
    $("#saveButtonCVR").on("click", onSaveButtonCVR_69419f0e);
    $("#change-vat-rate-form").on("submit", onChangeVatRateForm_69419f0e);
    $('input[name="vat_type"]').on("change", onChangeVatType_69419f0e);
}

export function unloadVatRatesPage() {
    delete window.showVatRates;
    delete window.changeVatRate;
    delete window.deleteVatRate;

    $("#saveButtonNVR").off("click", onSaveButtonNVR_69419f0e);
    $("#new-vat-rate-form").off("submit", onNewVatRateForm_69419f0e);
    $("#saveButtonCVR").off("click", onSaveButtonCVR_69419f0e);
    $("#change-vat-rate-form").off("submit", onChangeVatRateForm_69419f0e);
    $('input[name="vat_type"]').off("change", onChangeVatType_69419f0e);
}
