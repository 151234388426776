import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSenderChange_c6369bb8() {
    let senderHelper = $('#sender_helper');
    $("#email-name").prop("disabled", false);
    $("#email-email").prop("disabled", false);

    $.ajax({
        url: senderHelper.data('name'),
        type: "post",
        async: true,
        data: {"shop": $("#sender-shop").val()}
    }).done(function (response) {
        if (response.success) {
            $("#email-name").val(response.data.name);
            $("#email-email").val(response.data.email);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonES_c6369bb8(event) {
    toggleLoading('#saveButtonES', '#loadingButtonES');
    event.preventDefault();
    let senderHelper = $('#sender_helper');
    let form = $('#email-sender-form');
    let fd = new FormData(form[0]);
    let url = senderHelper.data('edit');
    let inputs = $(document).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonES', '#loadingButtonES');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initEmailSenderPage() {
    $("#sender-shop").on('change', onSenderChange_c6369bb8);
    $("#saveButtonES").on("click", onSaveButtonES_c6369bb8);
}

export function unloadEmailSenderPage() {
    $("#sender-shop").off('change', onSenderChange_c6369bb8);
    $("#saveButtonES").off("click", onSaveButtonES_c6369bb8);
}
