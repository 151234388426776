import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonNPM_013fb94d(event) {
    let form = $("#new-payment-mode-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewPaymentModeForm_013fb94d(event) {
    toggleLoading('#saveButtonNPM', '#loadingButtonNPM');
    event.preventDefault();
    let form = $(this);
    let paymentHelper = $('#payment_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = paymentHelper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showPaymentModes();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNPM', '#loadingButtonNPM');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCPM_013fb94d(event) {
    let form = $("#change-payment-mode-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangePaymentModeForm_013fb94d(event) {
    toggleLoading('#saveButtonCPM', '#loadingButtonCPM');
    event.preventDefault();
    let form = $(this);
    let paymentHelper = $('#payment_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = paymentHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showPaymentModes();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCPM', '#loadingButtonCPM');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initManagePaymentModesPage() {
    let paymentHelper = $('#payment_helper');

    window.showPaymentModes = function () {
        $.ajax({
            url: paymentHelper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#payment-types-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changePayment = function (id) {
        $.ajax({
            url: paymentHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-payment-type-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deletePayment = function (id, name) {
        deleteDialog(id, decodeURI(name), paymentHelper.data('delete-confirm'), paymentHelper.data('delete'), paymentHelper.data('delete-url'), () => {
            showPaymentModes();
        });
    };

    showPaymentModes();

    $("#saveButtonNPM").on("click", onSaveButtonNPM_013fb94d);
    $("#new-payment-mode-form").on("submit", onNewPaymentModeForm_013fb94d);
    $("#saveButtonCPM").on("click", onSaveButtonCPM_013fb94d);
    $("#change-payment-mode-form").on("submit", onChangePaymentModeForm_013fb94d);
}

export function unloadManagePaymentModesPage() {
    delete window.showPaymentModes;
    delete window.changePayment;
    delete window.deletePayment;

    $("#saveButtonNPM").off("click", onSaveButtonNPM_013fb94d);
    $("#new-payment-mode-form").off("submit", onNewPaymentModeForm_013fb94d);
    $("#saveButtonCPM").off("click", onSaveButtonCPM_013fb94d);
    $("#change-payment-mode-form").off("submit", onChangePaymentModeForm_013fb94d);
}
