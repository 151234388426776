import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import _ from "lodash";

function onSaveButtonNGM_10849787(event) {
    let form = $("#new-organisation-member-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewMemberForm_10849787(event) {
    toggleLoading('#saveButtonNGM', '#loadingButtonNGM');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('add');

    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#userTable').DataTable().ajax.reload(null, false);

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNGM', '#loadingButtonNGM');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCGM_10849787(event) {
    let form = $("#change-organisation-member-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeMemberForm_10849787(event) {
    toggleLoading('#saveButtonCGM', '#loadingButtonCGM');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('edit');

    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            $('#userTable').DataTable().ajax.reload(null, false);
            form.removeClass("was-validated");
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCGM', '#loadingButtonCGM');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initUsergroupMembersPage() {
    require('../datatables');

    let helper = $('#helper');

    let table = $('#userTable').DataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                'targets': 2,
                'responsivePriority': 1
            },
            {
                'targets': 3,
                'responsivePriority': 2
            }
        ],
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": helper.data('url')
    }).on('draw.dt', function () {
        feather.replace({
            width: 14,
            height: 14
        });

        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.changeMember = function (id) {
        $.ajax({
            url: helper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-group-member-id").val(id);
                $("#change-group-member-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteMember = function (id, name) {
        deleteDialog(id, decodeURI(name), helper.data('delete-confirm'), helper.data('delete-button'), helper.data('delete'), () => {
            table.ajax.reload(null, false);
        });
    };

    window.showActivity = function (id) {
        $.ajax({
            url: helper.data('activity'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#user-activity-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    $("#saveButtonNGM").on("click", onSaveButtonNGM_10849787);
    $("#new-organisation-member-form").on('submit', onNewMemberForm_10849787);
    $("#saveButtonCGM").on("click", onSaveButtonCGM_10849787);
    $("#change-organisation-member-form").on('submit', onChangeMemberForm_10849787);
}

export function unloadUsergroupMembersPage() {
    delete window.changeMember;
    delete window.deleteMember;
    delete window.showActivity;

    $("#saveButtonNGM").off("click", onSaveButtonNGM_10849787);
    $("#new-organisation-member-form").off('submit', onNewMemberForm_10849787);
    $("#saveButtonCGM").off("click", onSaveButtonCGM_10849787);
    $("#change-organisation-member-form").off('submit', onChangeMemberForm_10849787);
}
