import '../../app/vendors/css/forms/select/select2.min.css';
import select2 from 'select2';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onFoxpostNewPackageForm_47fb0c0f(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let orderId = $("#order_id");
    let aptId = $('#apt_id');
    let fd = new FormData(form[0]);
    let inputs = $(this).find("input, select, button, textarea");
    let url = helper.data('url');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        cache: false,
        contentType: false,
        processData: false,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);

            form.removeClass("was-validated");
            form.trigger("reset");

            $(".apt-row").addClass('d-none');
            $(".home-row").addClass('d-none');

            orderId.val(null).trigger('change');
            aptId.val(null).trigger('change');
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButton_47fb0c0f(event) {
    let form = $("#new-foxpost-package-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onSizeHome_47fb0c0f() {
    $('.home-row').removeClass('d-none');
    $('.apt-row').addClass('d-none');
}

function onSizeApt_47fb0c0f() {
    $('.apt-row').removeClass('d-none');
    $('.home-row').addClass('d-none');
}

function onOrderSelect_47fb0c0f(e) {
    let address = $('#address');
    let name = $('#name');
    name.val(e.params.data.name);
    address.val(e.params.data.address);
    $('#invoice_id').val(e.params.data.id);
    $('#email').val(e.params.data.email);
    $('#phone').val(e.params.data.phone);
    $('#postal_code').val(e.params.data.post);
    $('#city').val(e.params.data.city);
    $('#reference').val(e.params.data.order);
    $('#cod').val(e.params.data.number);
    $('#notes').val(e.params.data.note);
    $('#foxpost-address-limit').html(address.val().length.toString());
    $('#foxpost-name-limit').html(name.val().length);

    let optionOne = $('#apt_id option[value="' + e.params.data.place + '"]');
    let optionTwo = $('#apt_id option[data-operator="' + e.params.data.place + '"]');

    if (optionOne.length > 0) {
        $('#apt_id').val(optionOne.val()).trigger('change');
    } else if (optionTwo.length > 0) {
        $('#apt_id').val(optionTwo.val()).trigger('change');
    }

    if (e.params.data.place.length > 0) {
        $('#apt_s').prop('checked', true);
        $('.apt-row').removeClass('d-none');
        $('.home-row').addClass('d-none');
    } else {
        $('#home_s').prop('checked', true);
        $('.home-row').removeClass('d-none');
        $('.apt-row').addClass('d-none');
    }
}

function onAddressChange_47fb0c0f() {
    $('#foxpost-address-limit').html($('#address').val().length);
}

function onNameChange_47fb0c0f() {
    $('#foxpost-name-limit').html($('#name').val().length);
}

function onPatternChange_47fb0c0f() {
    if ($('.apt-row').hasClass('d-none')) {
        $('#address').prop('pattern', '.{0,30}');
    } else {
        $('#address').prop('pattern', '.{0,256}');
    }
}

export function initFoxpostNewPackagePage() {
    let helper = $('#helper');
    let orderId = $("#order_id");

    window.select2 = select2;

    if ($('html').attr('lang') === 'hu') {
        require('select2/dist/js/i18n/hu.js');
    }

    window.formatInvoice = function (invoice) {
        if (invoice.loading) {
            return invoice.text;
        }

        return "<div>" +
            "<div class='fs-lg fw-500 mb-1'>" + invoice.order + "</div>" +
            "<div class='row fs-sm'>" +
            "<div class='col-lg-4'><b>" + helper.data('date') + ":</b><br />" + invoice.date + "</div>" +
            "<div class='col-lg-4'><b>" + helper.data('name') + ":</b><br />" + invoice.name + "</div>" +
            "<div class='col-lg-4'><b>" + helper.data('total') + ":</b><br />" + invoice.total + "</div>" +
            "</div>" +
            "</div>";
    };

    window.formatInvoiceSelection = function (invoice) {
        return invoice.order || invoice.text;
    };

    $('#apt_id').select2();

    orderId.select2({
        ajax: {
            url: helper.data('invoices'),
            dataType: 'json',
            delay: 250,
            cache: true,
            type: 'post',
            async: true,
            data: function (params) {
                return {
                    q: params.term,
                    page: params.page
                };
            },
            processResults: function (data, params) {
                params.page = params.page || 1;

                return {
                    results: data.items,
                    pagination: {
                        more: (params.page * 30) < data.total_count
                    }
                };
            }
        },
        placeholder: helper.data('select'),
        escapeMarkup: function (markup) {
            return markup;
        },
        minimumInputLength: 1,
        templateResult: formatInvoice,
        templateSelection: formatInvoiceSelection
    });

    orderId.on("select2:select", onOrderSelect_47fb0c0f);
    $('#address').on('change input keyup', onAddressChange_47fb0c0f);
    $('#name').on('change input keyup', onNameChange_47fb0c0f);
    $('input[name="size"]').on('change', onPatternChange_47fb0c0f);
    $(".size-apt").on("click", onSizeApt_47fb0c0f);
    $('.size-home').on('click', onSizeHome_47fb0c0f);
    $("#saveButton").on("click", onSaveButton_47fb0c0f);
    $("#new-foxpost-package-form").on('submit', onFoxpostNewPackageForm_47fb0c0f);
}

export function unloadFoxpostNewPackagePage() {
    delete window.select2;
    delete window.formatInvoice;
    delete window.formatInvoiceSelection;

    let orderId = $('#order_id');

    orderId.select2('destroy');

    orderId.off("select2:select", onOrderSelect_47fb0c0f);
    $('#address').off('change input keyup', onAddressChange_47fb0c0f);
    $('#name').off('change input keyup', onNameChange_47fb0c0f);
    $('input[name="size"]').off('change', onPatternChange_47fb0c0f);
    $(".size-apt").off("click", onSizeApt_47fb0c0f);
    $('.size-home').off('click', onSizeHome_47fb0c0f);
    $("#saveButton").off("click", onSaveButton_47fb0c0f);
    $("#new-foxpost-package-form").off('submit', onFoxpostNewPackageForm_47fb0c0f);
}
