import _ from "lodash";

export function initUserLogPage() {
    let helper = $('#helper');

    require('../datatables');

    $('#userTable').dataTable({
        "responsive": true,
        "language": {
            "url": helper.data('lang')
        },
        "dom": '<"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "order": [[0, "desc"]],
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "orderCellsTop": false,
        "paging": true,
        "orderClasses": false,
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "ajax": helper.data('url')
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));
}

export function unloadUserLogPage() {
    $('#userTable').DataTable().destroy();
}
