import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSettingsChange_ea85302d(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $('#usergroup-settings-form');
    let fd = new FormData(form[0]);
    let url = $('#helper').data('url');
    let inputs = $(document).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButton', '#loadingButton');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initUsergroupSettingsPage() {
    $("button[type='submit']").on("click", onSettingsChange_ea85302d);
}

export function unloadUsergroupSettingsPage() {
    $("button[type='submit']").off("click", onSettingsChange_ea85302d);
}
