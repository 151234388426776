import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function onSaveButtonNPP_6a6ac0a9(event) {
    let form = $("#new-pickup-point-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewPickupPointForm_6a6ac0a9(event) {
    toggleLoading('#saveButtonNPP', '#loadingButtonNPP');
    event.preventDefault();
    let form = $(this);
    let pickupHelper = $('#pickup_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = pickupHelper.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showPickupPoints();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNPP', '#loadingButtonNPP');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCPP_6a6ac0a9(event) {
    let form = $("#change-pickup-point-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangePickupPointForm_6a6ac0a9(event) {
    toggleLoading('#saveButtonCPP', '#loadingButtonCPP');
    event.preventDefault();
    let form = $(this);
    let pickupHelper = $('#pickup_helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = pickupHelper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showPickupPoints();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCPP', '#loadingButtonCPP');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initManagePickupPointsPage() {
    let pickupHelper = $('#pickup_helper');

    window.showPickupPoints = function () {
        $.ajax({
            url: pickupHelper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#pickup-points-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changePickup = function (id) {
        $.ajax({
            url: pickupHelper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-pickup-point-type").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deletePickup = function (id, name) {
        deleteDialog(id, decodeURI(name), pickupHelper.data('delete-confirm'), pickupHelper.data('delete'), pickupHelper.data('delete-url'), () => {
            showPickupPoints();
        });
    };

    showPickupPoints();

    $("#saveButtonNPP").on("click", onSaveButtonNPP_6a6ac0a9);
    $("#new-pickup-point-form").on("submit", onNewPickupPointForm_6a6ac0a9);
    $("#saveButtonCPP").on("click", onSaveButtonCPP_6a6ac0a9);
    $("#change-pickup-point-form").on("submit", onChangePickupPointForm_6a6ac0a9);
}

export function unloadManagePickupPointsPage() {
    delete window.showPickupPoints;
    delete window.changePickup;
    delete window.deletePickup;

    $("#saveButtonNPP").off("click", onSaveButtonNPP_6a6ac0a9);
    $("#new-pickup-point-form").off("submit", onNewPickupPointForm_6a6ac0a9);
    $("#saveButtonCPP").off("click", onSaveButtonCPP_6a6ac0a9);
    $("#change-pickup-point-form").off("submit", onChangePickupPointForm_6a6ac0a9);
}
