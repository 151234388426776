import '../../app/vendors/css/editors/quill/quill.snow.css';
import '../../app/css/plugins/forms/form-quill-editor.css';
import Quill from 'quill';
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonST_d070e005(event) {
    let storedHelper = $('#stored_helper');
    toggleLoading('#saveButtonST', '#loadingButtonST');
    event.preventDefault();
    let form = $('#stored-templates-form');
    let fd = new FormData(form[0]);
    let url = storedHelper.data('edit');
    let inputs = $(document).find("input, select, button, textarea");

    inputs.prop("disabled", true);
    fd.append('text', $('#stored-templates-form .ql-editor').html());

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonST', '#loadingButtonST');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onTextModeChange_d070e005() {
    let storedHelper = $('#stored_helper');
    let textmode = $("#text-mode-stored").val();

    if (textmode !== null) {
        $('#full-container-stored .editor').data('quill').enable(true);
    }

    $.ajax({
        url: storedHelper.data('value'),
        type: "post",
        async: true,
        data: {
            "name": $("#text-action-stored").val(),
            "mode": textmode
        }
    }).done(function (response) {
        if (response.success) {
            $('#full-container-stored .editor').data('quill').clipboard.dangerouslyPasteHTML(response.data);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onTextActionChange_d070e005() {
    let storedHelper = $('#stored_helper');
    let textmode = $("#text-mode-stored").val();

    if (textmode !== null) {
        $('#full-container-stored .editor').data('quill').enable(true);
    }

    $.ajax({
        url: storedHelper.data('value'),
        type: "post",
        async: true,
        data: {
            "name": $("#text-action-stored").val(),
            "mode": textmode
        }
    }).done(function (response) {
        if (response.success) {
            $('#full-container-stored .editor').data('quill').clipboard.dangerouslyPasteHTML(response.data);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initStoredTemplatesPage() {
    window.Quill = Quill;

    window.addThisText = function (object) {
        if ($('#stored-templates').hasClass('active')) {
            let text = $('#stored-templates-form .ql-editor').html();
            if (text.slice(-1) !== " ") text += " ";
            $('#full-container-stored .editor').data('quill').clipboard.dangerouslyPasteHTML(text + $(object).html());
        } else {
            let text = $('#email-templates-form .ql-editor').html();
            if (text.slice(-1) !== " ") text += " ";
            $('#full-container-email .editor').data('quill').clipboard.dangerouslyPasteHTML(text + $(object).html());
        }
    };

    let quill = new Quill('#full-container-stored .editor', {
        bounds: '#full-container-stored .editor',
        modules: {
            toolbar: [
                [{font: []}, {size: []}],
                ['bold', 'italic', 'underline', 'strike'],
                [{color: []}, {background: []}],
                [{script: 'super'}, {script: 'sub'}],
                [{header: '1'}, {header: '2'}, 'blockquote', 'code-block'],
                [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
                ['direction', {align: []}],
                ['link', 'image', 'video', 'formula'],
                ['clean']
            ]
        },
        theme: 'snow'
    });

    quill.enable(false);
    $('#full-container-stored .editor').data('quill', quill);

    $("#text-action-stored").on('change', onTextActionChange_d070e005);
    $("#text-mode-stored").on('change', onTextModeChange_d070e005);
    $("#saveButtonST").on("click", onSaveButtonST_d070e005);
}

export function unloadStoredTemplatesPage() {
    delete window.Quill;
    delete window.addThisText;

    $("#text-action-stored").off('change', onTextActionChange_d070e005);
    $("#text-mode-stored").off('change', onTextModeChange_d070e005);
    $("#saveButtonST").off("click", onSaveButtonST_d070e005);
}
