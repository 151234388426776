import '../../app/vendors/css/forms/wizard/bs-stepper.min.css';
import '../../app/css/plugins/forms/form-wizard.css';

import Stepper from 'bs-stepper';
import {swalError, swalUnknown, toggleLoading} from "../functions";

function onStepperStepChange_d249a005(event) {
    event.preventDefault();

    let index = event.detail.indexStep;
    let numberOfSteps = $(event.target).find('.step').length - 1;
    let line = $(event.target).find('.step');

    for (let i = 0; i < index; i++) {
        line[i].classList.add('crossed');

        for (let j = index; j < numberOfSteps; j++) {
            line[j].classList.remove('crossed');
        }
    }

    if (event.detail.to === 0) {
        for (let k = index; k < numberOfSteps; k++) {
            line[k].classList.remove('crossed');
        }

        line[0].classList.remove('crossed');
    }
}

function onWizardSubmit_d249a005(event) {
    toggleLoading('.btn-success', '.btn-success-loading');
    event.preventDefault();

    let helper = $('#helper');
    let url = helper.data('save');
    let fd = new FormData();
    let forms = $('form');

    for (let i = 0; i < forms.length; i++) {
        let formValues = $(forms[i]).serializeArray();

        for (let j = 0; j < formValues.length; j++) {
            fd.append(formValues[j].name, formValues[j].value);
        }
    }

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            let swalWithBootstrapButtons = Swal.mixin({
                customClass:
                    {
                        confirmButton: "btn btn-success"
                    },
                buttonsStyling: false
            });

            swalWithBootstrapButtons.fire({
                title: response.data,
                text: helper.data('notice'),
                icon: "success",
                showCancelButton: false,
                confirmButtonText: helper.data('understood')
            }).then(function () {
                window.location.href = helper.data('redirect');
            });
        } else {
            swalError(response.data);
        }
    }).always(function () {
        toggleLoading('.btn-success', '.btn-success-loading');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onStepperNextButton_d249a005(event) {
    event.preventDefault();

    let helper = $('#helper');
    let feedLink = $('#feed-link');
    let wizard = $('.modern-wizard-example');

    if ($(this).parent().parent().valid()) {
        if ($('#feed-basic-data').hasClass('active')) {
            if (feedLink.val().toLowerCase().endsWith('csv')) {
                $('#csv-settings').removeClass('d-none');
                $('#xml-settings').addClass('d-none');
            }

            if (feedLink.val().toLowerCase().endsWith('xml')) {
                $('#xml-settings').removeClass('d-none');
                $('#csv-settings').addClass('d-none');
            }

            wizard.data('stepper').next();
        } else if ($('#feed-configuration').hasClass('active')) {
            let url = helper.data('fields');
            let fd = new FormData();
            let forms = $('form');

            for (let i = 0; i < forms.length; i++) {
                let formValues = $(forms[i]).serializeArray();

                for (let j = 0; j < formValues.length; j++) {
                    fd.append(formValues[j].name, formValues[j].value);
                }
            }

            toggleLoading('.btn-next', '.btn-loading');

            $.ajax({
                url: url,
                type: "post",
                data: fd,
                async: true,
                cache: false,
                contentType: false,
                processData: false
            }).done(function (response) {
                if (response.success) {
                    $('.feed-fields-div').html(response.data);
                }
            }).fail(function (e) {
                swalUnknown(e);
            }).always(function () {
                toggleLoading('.btn-next', '.btn-loading');
            });

            wizard.data('stepper').next();
        } else if ($('#feed-fields').hasClass('active')) {
            $('#summary-status').text($('#feed-status option:selected').text());
            $('#summary-name').text($('#feed-name').val());
            $('#summary-link').text(feedLink.val());
            $('#summary-priority').text($('#feed-priority').val());
            $('#summary-shops').text($('input[name="feed-shops[]"]:checked').length);

            $('#summary-time').text(
                $('select[name="day"] option:selected').text() +
                ', ' +
                $('select[name="hour"] option:selected').text() +
                ', ' +
                $('select[name="minute"] option:selected').text()
            );

            wizard.data('stepper').next();
        }
    }
}

function onStepperPreviousButton_d249a005(event) {
    event.preventDefault();

    $('.modern-wizard-example').data('stepper').previous();
}

export function initWholesaleFeedPage() {
    let wizard = document.querySelector('.modern-wizard-example');

    let stepper = new Stepper(wizard, {
        linear: true,
        animation: true
    });

    $(wizard).data('stepper', stepper);

    $('#feed-basic-data').validate({
        rules: {
            "feed-name": {
                required: true
            },
            "feed-link": {
                required: true,
                pattern: 'https?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_\\+.~#?&//=]*)(xml|csv)'
            },
            "feed-priority": {
                required: true
            }
        }
    });

    $(wizard).find('.btn-next').on('click', onStepperNextButton_d249a005);
    $(wizard).find('.btn-prev').on('click', onStepperPreviousButton_d249a005);
    $(wizard).find('.btn-save').on('click', onWizardSubmit_d249a005);

    $('.bs-stepper').on('show.bs-stepper', onStepperStepChange_d249a005);
}

export function unloadWholesaleFeedPage() {
    let wizard = document.querySelector('.modern-wizard-example');
    $(wizard).data('stepper').destroy();

    $(wizard).find('.btn-next').off('click', onStepperNextButton_d249a005);
    $(wizard).find('.btn-prev').off('click', onStepperPreviousButton_d249a005);
    $(wizard).find('.btn-save').off('click', onWizardSubmit_d249a005);

    $('.bs-stepper').off('show.bs-stepper', onStepperStepChange_d249a005);
}
