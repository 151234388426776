import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onServerChange_1d63323b() {
    let serverHelper = $('#server_helper');
    $("#email-host").prop("disabled", false);
    $("#email-port").prop("disabled", false);
    $("#email-username").prop("disabled", false);
    $("#email-password").prop("disabled", false);

    $.ajax({
        url: serverHelper.data('get'),
        type: "post",
        async: true,
        data: {"shop": $("#server-shop").val()}
    }).done(function (response) {
        if (response.success) {
            $("#email-host").val(response.data.host);
            $("#email-port").val(response.data.port);
            $("#email-username").val(response.data.username);
            $("#email-password").val(response.data.password);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onTestCredentials_1d63323b(event) {
    event.preventDefault();
    let form = $("#email-server-form");
    let serverHelper = $('#server_helper');
    let inputs = form.find("input, select, button, textarea");
    let serializedData = "host=" + $("#email-host").val() + "&port=" + $("#email-port").val() +
        "&username=" + $("#email-username").val() + "&password=" + $("#email-password").val();
    inputs.prop("disabled", true);

    $.ajax({
        url: serverHelper.data('test'),
        type: "post",
        data: serializedData,
        async: true
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonEH_1d63323b(event) {
    toggleLoading('#saveButtonEH', '#loadingButtonEH');
    event.preventDefault();
    let serverHelper = $('#server_helper');
    let form = $('#email-server-form');
    let fd = new FormData(form[0]);
    let url = serverHelper.data('edit');
    let inputs = $(document).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        async: true,
        cache: false,
        contentType: false,
        processData: false,
        data: fd
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonEH', '#loadingButtonEH');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initEmailServerPage() {
    $("#server-shop").on('change', onServerChange_1d63323b);
    $("#test-credentials").on("click", onTestCredentials_1d63323b);
    $("#saveButtonEH").on("click", onSaveButtonEH_1d63323b);
}

export function unloadEmailServerPage() {
    $("#server-shop").off('change', onServerChange_1d63323b);
    $("#test-credentials").off("click", onTestCredentials_1d63323b);
    $("#saveButtonEH").off("click", onSaveButtonEH_1d63323b);
}
