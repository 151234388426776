let $ = require('jquery');

import feather from 'feather-icons';
import * as bootstrap from "bootstrap";
import PerfectScrollbar from 'perfect-scrollbar';
import TouchSpin from 'bootstrap-touchspin';
import Unison from '../app/vendors/js/unison-js/unison-js.min';
import Waves from 'node-waves';

require('bootstrap');
require('jquery-sticky');
require('jquery-validation');
require('jquery-validation/dist/additional-methods.min');

window.$ = $;
window.jQuery = $;
window.feather = feather;
window.Waves = Waves;
window.bootstrap = bootstrap;
window.TouchSpin = TouchSpin;
window.PerfectScrollbar = PerfectScrollbar;
window.unison = Unison;

require('../app/js/core/app-menu.min');
require('../app/js/core/app');

if ($('html').attr('lang') === 'hu') {
    require('jquery-validation/dist/localization/messages_hu.min');
}

let routes = ['/app/', '/admin/', '/modul/', '/module/'];

if (routes.some(v => window.location.href.includes(v))) {
    require('./swup');
}
