import {swalError, swalSuccess, swalUnknown} from "../functions";

function onCronjobSubmit_51b47b43() {
    let element = $(this);
    let form = element.parents('form');
    let inputs = form.find("input, select, button, textarea");
    let status = form.find('select.status').val();
    let fd = new FormData(form[0]);
    let url = $('#helper').data('url');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            swalSuccess(response.data);

            if (status === '1') {
                element.parents('.card').children(".card-header").addClass("bg-success");
                element.parents('.card').children(".card-header").removeClass("bg-danger");
            } else {
                element.parents('.card').children(".card-header").removeClass("bg-success");
                element.parents('.card').children(".card-header").addClass("bg-danger");
            }
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initCronjobPage() {
    $("button[type='submit']").on("click", onCronjobSubmit_51b47b43);
}

export function unloadCronjobPage() {
    $("button[type='submit']").off("click", onCronjobSubmit_51b47b43);
}
