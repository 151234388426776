import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function onSaveButtonNI_1a25020a(event) {
    let form = $("#new-investor-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewInvestorGroupForm_1a25020a(event) {
    toggleLoading('#saveButtonNI', '#loadingButtonNI');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('new');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showInvestorsDiv();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNI', '#loadingButtonNI');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCI_1a25020a(event) {
    let form = $("#change-investor-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeInvestorGroupForm_1a25020a(event) {
    toggleLoading('#saveButtonCI', '#loadingButtonCI');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showInvestorsDiv();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCI', '#loadingButtonCI');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initInvestorGroupsPage() {
    let helper = $('#helper');

    window.showInvestorsDiv = function () {
        $.ajax({
            url: helper.data('div'),
            type: "post",
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#investors-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeInput = function (id) {
        $.ajax({
            url: helper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-investor-group-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteInput = function (id, name) {
        deleteDialog(id, decodeURI(name), helper.data('delete-confirm'), helper.data('delete'), helper.data('delete-url'), () => {
            showInvestorsDiv();
        });
    };

    showInvestorsDiv();

    $("#saveButtonNI").on("click", onSaveButtonNI_1a25020a);
    $("#new-investor-form").on("submit", onNewInvestorGroupForm_1a25020a);
    $("#saveButtonCI").on("click", onSaveButtonCI_1a25020a);
    $("#change-investor-form").on("submit", onChangeInvestorGroupForm_1a25020a);
}

export function unloadInvestorGroupsPage() {
    delete window.showInvestorsDiv;
    delete window.changeInput;
    delete window.deleteInput;

    $("#saveButtonNI").off("click", onSaveButtonNI_1a25020a);
    $("#new-investor-form").off("submit", onNewInvestorGroupForm_1a25020a);
    $("#saveButtonCI").off("click", onSaveButtonCI_1a25020a);
    $("#change-investor-form").off("submit", onChangeInvestorGroupForm_1a25020a);
}
