import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";
import feather from "feather-icons";

function onSaveButtonNPC_5321fe36(event) {
    let form = $("#new-product-category-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewCategoryForm_5321fe36(event) {
    toggleLoading('#saveButtonNPC', '#loadingButtonNPC');
    event.preventDefault();
    let form = $(this);
    let categories = $('#categories-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = categories.data('add');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showProductCategories();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNPC', '#loadingButtonNPC');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCPC_5321fe36(event) {
    let form = $("#change-product-category-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeCategoryForm_5321fe36(event) {
    toggleLoading('#saveButtonCPC', '#loadingButtonCPC');
    event.preventDefault();
    let form = $(this);
    let categories = $('#categories-helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = categories.data('edit');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showProductCategories();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCPC', '#loadingButtonCPC');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initProductCategoriesPage() {
    let categories = $('#categories-helper');

    window.showProductCategories = function () {
        $.ajax({
            url: categories.data('div'),
            type: 'post',
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#product-categories-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeProductCategory = function (id) {
        $.ajax({
            url: categories.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-product-category-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteProductCategory = function (id, name) {
        deleteDialog(id, decodeURI(name), categories.data('delete-confirm'), categories.data('delete'), categories.data('delete-url'), () => {
            showProductCategories();
        });
    };

    showProductCategories();

    $("#saveButtonNPC").on("click", onSaveButtonNPC_5321fe36);
    $("#new-product-category-form").on("submit", onNewCategoryForm_5321fe36);
    $("#saveButtonCPC").on("click", onSaveButtonCPC_5321fe36);
    $("#change-product-category-form").on("submit", onChangeCategoryForm_5321fe36);
}

export function unloadProductCategoriesPage() {
    delete window.showProductCategories;
    delete window.changeProductCategory;
    delete window.deleteProductCategory;

    $("#saveButtonNPC").off("click", onSaveButtonNPC_5321fe36);
    $("#new-product-category-form").off("submit", onNewCategoryForm_5321fe36);
    $("#saveButtonCPC").off("click", onSaveButtonCPC_5321fe36);
    $("#change-product-category-form").off("submit", onChangeCategoryForm_5321fe36);
}
