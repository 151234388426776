import {swalError, swalSuccess} from "../functions";

function onDownloadProducts_2358c1e8() {
    let helper = $('#helper');

    $.ajax({
        url: helper.data('shops'),
        type: "post",
        async: true
    }).done(function (response) {
        Swal.fire({
            title: helper.data('select'),
            input: 'select',
            width: '35rem',
            inputOptions: response,
            customClass: {
                input: 'form-control w-auto'
            },
            showCancelButton: true,
            confirmButtonText: helper.data('go'),
            cancelButtonText: helper.data('cancel'),
            showLoaderOnConfirm: true,
            backdrop: true,
            allowOutsideClick: () => !Swal.isLoading(),
            allowEscapeKey: () => !Swal.isLoading(),
            preConfirm: function preConfirm(id) {
                return fetch(helper.data('download').concat(id))
                    .then(function (response) {
                        if (!response.ok) {
                            throw new Error(response.statusText);
                        }

                        return response.json();
                    })
                    .catch(function () {
                        Swal.showValidationMessage(helper.data('error'));
                    });
            }
        }).then(function (result) {
            if (typeof result.value === 'undefined') {
                return;
            }

            if (result.value.success) {
                swalSuccess(result.value.data);
            } else {
                swalError(result.value.data);
            }
        });
    });
}

function onSyncOrder_2358c1e8() {
    let helper = $('#helper');

    Swal.fire({
        title: helper.data('order'),
        width: '35rem',
        html: $('#sync-order-helper').html(),
        showCancelButton: true,
        confirmButtonText: helper.data('go'),
        cancelButtonText: helper.data('cancel'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
        allowEscapeKey: () => !Swal.isLoading(),
        preConfirm: () => {
            return fetch(helper.data('sync').concat($('.swal2-html-container #sync-order-shop').val()).concat('/').concat($('.swal2-html-container #sync-order-id').val()))
                .then(function (response) {
                    if (!response.ok) {
                        throw new Error(response.statusText);
                    }

                    return response.json();
                })
                .catch(function () {
                    Swal.showValidationMessage(helper.data('error'));
                });
        }
    }).then(function (result) {
        if (typeof result.value === 'undefined') {
            return;
        }

        if (result.value.success) {
            swalSuccess(result.value.data);
        } else {
            swalError(result.value.data);
        }
    });
}

function onDeletePersonalData_2358c1e8() {
    let helper = $('#helper');

    Swal.fire({
        title: helper.data('order'),
        text: helper.data('help'),
        width: '35rem',
        html: '<div class="form-check">' +
            '     <input class="form-check-input" type="checkbox" id="delete-personal-contact" value="1" />' +
            '     <label class="form-check-label float-start" for="delete-personal-contact">' + helper.data('delete-contact') + '</label>' +
            '</div>' +
            '<div class="form-check">' +
            '     <input class="form-check-input" type="checkbox" id="delete-personal-billing" value="1" />' +
            '     <label class="form-check-label float-start" for="delete-personal-billing">' + helper.data('delete-billing') + '</label>' +
            '</div>' +
            '<div class="form-check">' +
            '     <input class="form-check-input" type="checkbox" id="delete-personal-shipping" value="1" />' +
            '     <label class="form-check-label float-start" for="delete-personal-shipping">' + helper.data('delete-shipping') + '</label>' +
            '</div>',
        showCancelButton: true,
        confirmButtonText: helper.data('go'),
        cancelButtonText: helper.data('cancel'),
        showLoaderOnConfirm: true,
        allowOutsideClick: () => !Swal.isLoading(),
        backdrop: true,
        allowEscapeKey: () => !Swal.isLoading(),
        preConfirm: function preConfirm() {
            return fetch(helper.data('personal'), {
                method: "POST",
                mode: "same-origin",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {"Content-Type": "application/x-www-form-urlencoded"},
                referrerPolicy: "no-referrer",
                body: "contact=" + $('#delete-personal-contact').is(':checked') + "&shipping=" + $('#delete-personal-shipping').is(':checked') +
                    "&billing=" + $('#delete-personal-billing').is(':checked')
            }).then(function (response) {
                if (!response.ok) {
                    throw new Error(response.statusText);
                }

                return response.json();
            })
                .catch(function () {
                    Swal.showValidationMessage(helper.data('error'));
                });
        }
    }).then(function (result) {
        if (typeof result.value === 'undefined') {
            return;
        }

        if (result.value.success) {
            swalSuccess(result.value.data);
        } else {
            swalError(result.value.data);
        }
    });
}

export function initToolsListPage() {
    $("#download-webshop-products").on("click", onDownloadProducts_2358c1e8);
    $("#sync-webshop-order").on("click", onSyncOrder_2358c1e8);
    $("#delete-personal-data").on("click", onDeletePersonalData_2358c1e8);
}

export function unloadToolsListPage() {
    $("#download-webshop-products").off("click", onDownloadProducts_2358c1e8);
    $("#sync-webshop-order").off("click", onSyncOrder_2358c1e8);
    $("#delete-personal-data").off("click", onDeletePersonalData_2358c1e8);
}
