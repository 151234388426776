import '../../app/vendors/css/file-uploaders/dropzone.min.css';
import '../../app/css/plugins/forms/form-file-uploader.css';
import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';

import Dropzone from 'dropzone';
import feather from "feather-icons";
import flatpickr from "flatpickr";
import {swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

Dropzone.autoDiscover = false;

let stream, guid;

function onInitialTableUpload_3b07bbe6(file, response) {
    if (response.success) {
        $('#dpz-import-file').remove();
        $('#xls-processor-page .card-body').html(response.data);

        $('#completion_date').flatpickr({
            dateFormat: "Y.m.d",
            allowInput: true
        });

        guid = response.guid;

        feather.replace({
            width: 14,
            height: 14
        });
    }
}

function onCheckAllClick_3b07bbe6() {
    if ($('#check-all').is(':checked')) {
        $('tbody .form-check-input:not(:disabled)').prop('checked', true);
    } else {
        $('tbody .form-check-input').prop('checked', false);
    }
}

function onSubmitForm_3b07bbe6(event) {
    toggleLoading('#saveButton', '#loadingButton');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let fd = new FormData(form[0]);
    let url = helper.data('url');
    let inputs = $(this).find("input, select, button, textarea");
    inputs.prop("disabled", true);

    fd.append('guid', guid);
    $('input[name="orders[]"]:checked').parents('th').siblings('.status.text-center').text(helper.data('processing'));

    const notifications = new URL($('.notification-options').data('url'));
    notifications.searchParams.append('topic', guid);

    stream = new EventSource(notifications.toString());
    stream.onmessage = event => {
        let data = JSON.parse(event.data);
        $('#' + data.order + '-row td.status').text(data.message);
    };

    stream.onopen = () => {
        $.ajax({
            url: url,
            type: 'POST',
            data: fd,
            cache: false,
            contentType: false,
            processData: false,
            async: true
        }).done(function (response) {
            if (response.success) {
                swalSuccess(response.data);
                form.removeClass("was-validated");

                if (response.filename.length) {
                    let a = document.createElement('a');

                    if (window.URL && window.Blob && ('download' in a) && window.atob) {
                        let blob = base64ToBlob(response.file, response.mimetype);
                        let url = window.URL.createObjectURL(blob);
                        a.href = url;
                        a.download = response.filename;
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }
                }
            } else {
                swalError(response.data);
            }
        }).always(function () {
            inputs.prop("disabled", false);
            $('.non-existent-order input').prop('disabled', true);
            toggleLoading('#saveButton', '#loadingButton');
            stream.close();
        }).fail(function (e) {
            swalUnknown(e);
        });
    };
}

export function initXlsProcessorPage() {
    window.flatpickr = flatpickr;

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    window.base64ToBlob = function (base64, mimetype, slicesize) {
        if (!window.atob || !window.Uint8Array) {
            return null;
        }

        let mime = mimetype || '';
        let chunk = slicesize || 512;
        let bytechars = atob(base64);
        let bytearrays = [];

        for (let offset = 0; offset < bytechars.length; offset += chunk) {
            let slice = bytechars.slice(offset, offset + chunk);
            let bytenums = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                bytenums[i] = slice.charCodeAt(i);
            }
            bytearrays[bytearrays.length] = new Uint8Array(bytenums);
        }

        return new Blob(bytearrays, {type: mime});
    };

    let dz = new Dropzone('#dpz-import-file', {
        paramName: 'cod-xls',
        maxFiles: 1,
        maxFilesize: 5,
        acceptedFiles: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv',
        url: $('#helper').data('upload')
    });

    dz.on('success', onInitialTableUpload_3b07bbe6);
    $('#dpz-import-file').data('dz', dz);

    $(document).on('click', '#check-all', onCheckAllClick_3b07bbe6);
    $(document).on('submit', '#import-file-form', onSubmitForm_3b07bbe6);
}

export function unloadXlsProcessorPage() {
    delete window.flatpickr;
    delete window.base64ToBlob;

    $('.flatpickr-calendar').remove();
    $('#dpz-import-file').data('dz').off('success', onInitialTableUpload_3b07bbe6);

    if (typeof stream === "object") {
        stream.close();
    }

    $(document).off('click', '#check-all', onCheckAllClick_3b07bbe6);
    $(document).off('submit', '#import-file-form', onSubmitForm_3b07bbe6);
}