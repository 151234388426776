import {swalError, swalUnknown} from "../functions";

function onPermissionChange_d62923f5() {
    let mode;
    let perm = $('#perm');
    let action = $(this).attr("id").split("-")[0];
    let role = $(this).attr("id").split("-")[1];

    if ($(this).is(':checked')) {
        mode = 'add';
    } else {
        mode = 'remove';
    }

    $.ajax({
        url: perm.data('url'),
        type: "post",
        async: true,
        data: "action_name=" + action + "&role=" + role + "&mode=" + mode
    }).done(function (response) {
        if (response.success) {
            Swal.fire({
                position: 'top-end',
                toast: true,
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
                title: response.data
            }).then();
        } else {
            swalError(response.data);
        }
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initPersmissionsPage() {
    $(".form-check-input").on("change", onPermissionChange_d62923f5);
}

export function unloadPermissionsPage() {
    $(".form-check-input").off("change", onPermissionChange_d62923f5);
}