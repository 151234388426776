import '../../app/css/plugins/forms/pickers/form-flat-pickr.css';
import '../../app/vendors/css/pickers/flatpickr/flatpickr.min.css';
import flatpickr from "flatpickr";
import feather from "feather-icons";
import {deleteDialog, swalError, swalSuccess, swalUnknown, toggleLoading} from "../functions";

function onSaveButtonNI_76b7d00b(event) {
    let form = $("#new-investor-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onNewInvestorMemberForm_76b7d00b(event) {
    toggleLoading('#saveButtonNI', '#loadingButtonNI');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('new');

    inputs.prop("disabled", true);
    fd.append('group', $('#group_id').val());

    $.ajax({
        url: url,
        type: "post",
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showInvestorsDiv();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonNI', '#loadingButtonNI');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onSaveButtonCI_76b7d00b(event) {
    let form = $("#change-investor-form");

    if (form[0].checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    }

    form.addClass('was-validated');
}

function onChangeInvestorMemberForm_76b7d00b(event) {
    toggleLoading('#saveButtonCI', '#loadingButtonCI');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('edit');

    inputs.prop("disabled", true);
    fd.append('group', $('#group_id').val());

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showInvestorsDiv();
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonCI', '#loadingButtonCI');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

function onInvestorMemberPayoutForm_76b7d00b(event) {
    toggleLoading('#saveButtonPI', '#loadingButtonPI');
    event.preventDefault();
    let form = $(this);
    let helper = $('#helper');
    let inputs = form.find("input, select, button, textarea");
    let fd = new FormData(form[0]);
    let url = helper.data('payout');
    inputs.prop("disabled", true);

    $.ajax({
        url: url,
        type: 'POST',
        data: fd,
        async: true,
        cache: false,
        contentType: false,
        processData: false
    }).done(function (response) {
        if (response.success) {
            showInvestorsDiv();

            form.trigger("reset");
            form.removeClass("was-validated");

            swalSuccess(response.data);
        } else {
            swalError(response.data);
        }
    }).always(function () {
        inputs.prop("disabled", false);
        toggleLoading('#saveButtonPI', '#loadingButtonPI');
    }).fail(function (e) {
        swalUnknown(e);
    });
}

export function initInvestorMembersPage() {
    window.flatpickr = flatpickr;

    let helper = $('#helper');

    if ($('html').attr('lang') === 'hu') {
        const hu = require("flatpickr/dist/l10n/hu").default.hu;
        flatpickr.localize(hu);
    }

    window.showInvestorsDiv = function () {
        $.ajax({
            url: helper.data('div'),
            type: "post",
            data: 'ID=' + $('#group_id').val(),
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#investors-div").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.changeInput = function (id) {
        $.ajax({
            url: helper.data('form'),
            type: "post",
            data: {"ID": id},
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#change-investor-member-div").html(response.data);
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.deleteInput = function (id, name) {
        deleteDialog(id, decodeURI(name), helper.data('delete-confirm'), helper.data('delete'), helper.data('delete-url'), () => {
            showInvestorsDiv();
        });
    };

    window.listPayouts = function (id) {
        $.ajax({
            url: helper.data('payout-list'),
            type: "post",
            data: 'ID=' + id,
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#list-payouts-modal .modal-body").html(response.data);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    window.revokePayout = function (id) {
        let swalWithBootstrapButtons = Swal.mixin({
            customClass:
                {
                    confirmButton: "btn btn-danger me-2",
                    cancelButton: "btn btn-secondary"
                },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: helper.data('revoke-conf'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: helper.data('revoke'),
            cancelButtonText: helper.data('cancel')
        }).then(function (result) {
            if (result.value) {
                let url = helper.data('revoke-url');

                $.ajax({
                    url: url,
                    type: "post",
                    data: "ID=" + id,
                    async: true
                }).done(function (response) {
                    if (response.success) {
                        showInvestorsDiv();

                        swalSuccess(response.data);
                    } else {
                        swalError(response.data);
                    }
                }).fail(function (e) {
                    swalUnknown(e);
                });
            }
        });
    };

    window.payoutInvestor = function (id, max, balance, name) {
        $('#payout-investor-id').val(id);
        $('#payout-investor-name').val(name);
        $('#payout-investor-balance').val(balance);
    };

    showInvestorsDiv();

    $('#payout-investor-date').flatpickr({
        dateFormat: "Y.m.d.",
        allowInput: true
    });

    $("#saveButtonNI").on("click", onSaveButtonNI_76b7d00b);
    $("#new-investor-form").on("submit", onNewInvestorMemberForm_76b7d00b);
    $("#saveButtonCI").on("click", onSaveButtonCI_76b7d00b);
    $("#change-investor-form").on("submit", onChangeInvestorMemberForm_76b7d00b);
    $("#investor-payout-form").on("submit", onInvestorMemberPayoutForm_76b7d00b);
}

export function unloadInvestorMembersPage() {
    delete window.flatpickr;
    delete window.showInvestorsDiv;
    delete window.changeInput;
    delete window.deleteInput;
    delete window.listPayouts;
    delete window.revokePayout;
    delete window.payoutInvestor;

    $('.flatpickr-calendar').remove();

    $("#saveButtonNI").off("click", onSaveButtonNI_76b7d00b);
    $("#new-investor-form").off("submit", onNewInvestorMemberForm_76b7d00b);
    $("#saveButtonCI").off("click", onSaveButtonCI_76b7d00b);
    $("#change-investor-form").off("submit", onChangeInvestorMemberForm_76b7d00b);
    $("#investor-payout-form").off("submit", onInvestorMemberPayoutForm_76b7d00b);
}
