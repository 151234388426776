import '../../app/css/plugins/charts/chart-apex.css';
import '../../app/vendors/css/charts/apexcharts.css';
import Sortable from 'sortablejs';
import ApexCharts from 'apexcharts';

function onMonthYearChange_5b7377fb() {
    let url = $('#helper').data('url');

    url = url.replace('year', $('#year').val());
    url = url.replace('month', $('#month').val());

    window.location.href = url;
}

export function initProductReportPage() {
    window.Sortable = Sortable;
    window.ApexCharts = ApexCharts;

    let helper = $('#helper');
    let year = $('#year'), month = $('#month');

    $('#card-drag-area').data('sort', Sortable.create(document.getElementById('card-drag-area'), {
        animation: 150,
        draggable: '.draggable',
        dataIdAttr: 'id',
        dragoverBubble: true,
        delay: 1000,
        delayOnTouchOnly: true,
        group: "CrossBolt_product_report",
        store: {
            get: function (sortable) {
                let order = localStorage.getItem(sortable.options.group.name);

                if (order) {
                    return order.split('|');
                } else {
                    return [];
                }
            },
            set: function (sortable) {
                let order = sortable.toArray();
                localStorage.setItem(sortable.options.group.name, order.join('|'));

                $.ajax({
                    url: helper.data('page'),
                    type: "post",
                    data: "k=" + sortable.options.group.name + "&v=" + order.join('|') + "&p=product_report",
                    async: true
                });
            }
        }
    }));

    let uniqueProductsChart = new ApexCharts(document.querySelector("#unique_products"), {
        series: [{
            name: helper.data('count'),
            data: helper.data('unique-products').replaceAll("\\n", "\n").split("\n").map(function (x) {
                return x.split(',').map(function (y) {
                    return parseInt(y);
                });
            })
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }
        }
    });

    let totalSellableChart = new ApexCharts(document.querySelector("#total_sellable"), {
        series: [{
            name: helper.data('count'),
            data: helper.data('total-products').replaceAll("\\n", "\n").split("\n").map(function (x) {
                return x.split(',').map(function (y) {
                    return parseInt(y);
                });
            })
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
                }
            }
        }
    });

    let productWeightChart = new ApexCharts(document.querySelector("#products_weight"), {
        series: [{
            name: helper.data('weight'),
            data: helper.data('products-weight').replaceAll("\\n", "\n").split("\n").map(function (x) {
                return x.split(',').map(function (y) {
                    return parseInt(y);
                });
            })
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' kg';
                }
            }
        }
    });

    let totalCostChart = new ApexCharts(document.querySelector("#total_cost"), {
        series: [{
            name: helper.data('value'),
            data: helper.data('total-cost').replaceAll("\\n", "\n").split("\n").map(function (x) {
                return x.split(',').map(function (y) {
                    return parseInt(y);
                });
            })
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + helper.data('currency');
                }
            }
        }
    });

    let sellableProductsChart = new ApexCharts(document.querySelector("#sellable_products"), {
        series: [{
            name: helper.data('value'),
            data: helper.data('sellable-products').replaceAll("\\n", "\n").split("\n").map(function (x) {
                return x.split(',').map(function (y) {
                    return parseInt(y);
                });
            })
        }],
        chart: {
            type: 'area',
            stacked: false,
            height: 350,
            zoom: {
                type: 'x',
                enabled: true,
                autoScaleYaxis: true
            },
            toolbar: {
                autoSelected: 'zoom'
            }
        },
        dataLabels: {
            enabled: false
        },
        markers: {
            size: 0,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.5,
                opacityTo: 0,
                stops: [0, 90, 100]
            },
        },
        xaxis: {
            type: 'datetime',
        },
        tooltip: {
            shared: false,
            y: {
                formatter: function (val) {
                    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".") + ' ' + helper.data('currency');
                }
            }
        }
    });

    uniqueProductsChart.render().then();
    totalSellableChart.render().then();
    productWeightChart.render().then();
    totalCostChart.render().then();
    sellableProductsChart.render().then();

    $('#sellable_products').data('chart', sellableProductsChart);
    $('#total_cost').data('chart', totalCostChart);
    $('#products_weight').data('chart', productWeightChart);
    $('#total_sellable').data('chart', totalSellableChart);
    $('#unique_products').data('chart', uniqueProductsChart);

    year.on('change', onMonthYearChange_5b7377fb);
    month.on('change', onMonthYearChange_5b7377fb);
}

export function unloadProductReportPage() {
    delete window.Sortable;
    delete window.ApexCharts;

    let sellableProducts = $('#sellable_products');
    let totalCost = $('#total_cost');
    let productWeight = $('#products_weight');
    let totalSellable = $('#total_sellable');
    let uniqueProducts = $('#unique_products');

    $('#card-drag-area').data('sort').destroy();
    $('#year').off('change', onMonthYearChange_5b7377fb);
    $('#month').off('change', onMonthYearChange_5b7377fb);

    if (typeof sellableProducts.data('chart') !== 'undefined') {
        sellableProducts.data('chart').destroy();
    }

    if (typeof totalCost.data('chart') !== 'undefined') {
        totalCost.data('chart').destroy();
    }

    if (typeof productWeight.data('chart') !== 'undefined') {
        productWeight.data('chart').destroy();
    }

    if (typeof totalSellable.data('chart') !== 'undefined') {
        totalSellable.data('chart').destroy();
    }

    if (typeof uniqueProducts.data('chart') !== 'undefined') {
        uniqueProducts.data('chart').destroy();
    }
}
