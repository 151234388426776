import feather from "feather-icons";
import {swalUnknown} from "../functions";
import _ from "lodash";

function onAttributeChange_4091d8da() {
    $('#myTable').DataTable().ajax.reload(null, false);
}

export function initIncomingInvoiceListPage() {
    require('../datatables');

    let helper = $('#helper');
    let year = $("#year");
    let month = $("#month");
    let partner = $("#partner");

    $('#myTable').DataTable({
        "dom": '<"card-header border-bottom p-1"<"head-label"><"dt-action-buttons text-end"B>><"d-flex justify-content-between align-items-center mx-0 row"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>t<"d-flex justify-content-between mx-0 row"<"col-sm-12 col-md-6"i><"col-sm-12 col-md-6"p>>',
        "lengthMenu": [[10, 25, 50, 100, 500, 1000, -1], [10, 25, 50, 100, 500, 1000, helper.data('all')]],
        "pageLength": 25,
        "ajax": {
            "url": helper.data('url'),
            "async": true,
            "data": function (d) {
                d.year = year.val();
                d.month = month.val();
                d.partner = partner.val();
            },
            "cache": false,
            "method": 'GET',
            "pages": 2
        },
        "order": [[0, 'desc']],
        "language": {
            "url": helper.data('lang')
        },
        "deferRender": true,
        "processing": true,
        "serverSide": true,
        "searchDelay": 750,
        "stateSave": true,
        "paging": true,
        "responsive": true,
        "buttons": [
            {
                extend: 'collection',
                className: 'btn btn-outline-secondary me-2',
                text: feather.icons.share.toSvg({class: 'font-small-4 me-50'}) + helper.data('export'),
                buttons: [
                    {
                        extend: 'print',
                        text: feather.icons.printer.toSvg({class: 'font-small-4 me-50'}) + helper.data('print'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        },
                        orientation: 'landscape',
                        customize: function (win) {
                            let css = '@page { size: landscape; }',
                                head = win.document.head || win.document.getElementsByTagName('head')[0],
                                style = win.document.createElement('style');
                            style.type = 'text/css';
                            style.media = 'print';
                            if (style.styleSheet) {
                                style.styleSheet.cssText = css;
                            } else {
                                style.appendChild(win.document.createTextNode(css));
                            }
                            head.appendChild(style);
                        }
                    },
                    {
                        extend: 'csvHtml5',
                        text: feather.icons['file-text'].toSvg({class: 'font-small-4 me-50'}) + 'Csv',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        text: feather.icons.file.toSvg({class: 'font-small-4 me-50'}) + 'Excel',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'pdfHtml5',
                        text: feather.icons.clipboard.toSvg({class: 'font-small-4 me-50'}) + 'Pdf',
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    },
                    {
                        extend: 'copyHtml5',
                        text: feather.icons.copy.toSvg({class: 'font-small-4 me-50'}) + helper.data('copy'),
                        className: 'dropdown-item',
                        exportOptions: {
                            columns: [0, 1, 2, 3, 4],
                            modifier: {
                                order: 'current',
                                page: 'all',
                                selected: null
                            }
                        }
                    }
                ],
                init: function (api, node) {
                    $(node).removeClass('btn-secondary');
                    $(node).parent().removeClass('btn-group');
                    setTimeout(function () {
                        $(node).closest('.dt-buttons').removeClass('btn-group').addClass('d-inline-flex');
                    }, 50);
                }
            }
        ],
        "columnDefs": [
            {
                'targets': 'no-sort',
                'orderable': false
            },
            {
                targets: 2,
                responsivePriority: 2,
            },
            {
                targets: 0,
                responsivePriority: 1
            },
        ],
    }).on('draw.dt', function () {
        $('.dataTables_filter input').removeClass('form-control-sm').addClass('form-control');
        $('.dataTables_length select').removeClass('form-select-sm').removeClass('form-control-sm').addClass('form-select');

        feather.replace({
            width: 14,
            height: 14
        });
    }).on('responsive-display', function () {
        feather.replace({
            width: 14,
            height: 14
        });
    });

    let search = $('input[type=search].form-control');

    search.on("keyup", _.debounce(function () {
        table.search(search.val()).draw();
    }, 500));

    window.viewProducts = function (id) {
        $.ajax({
            url: helper.data('show-products'),
            type: "post",
            data: "ID=" + id,
            async: true
        }).done(function (response) {
            if (response.success) {
                $("#show-invoice-products-modal #invoice-content").html(response.html);
                $("#show-invoice-products-modal #invoice-number").val(response.number);
                $("#show-invoice-products-modal #invoice-date").val(response.date);

                feather.replace({
                    width: 14,
                    height: 14
                });
            }
        }).fail(function (e) {
            swalUnknown(e);
        });
    };

    year.on('change', onAttributeChange_4091d8da);
    month.on('change', onAttributeChange_4091d8da);
    partner.on('change', onAttributeChange_4091d8da);
}

export function unloadIncomingInvoiceListPage() {
    delete window.viewProducts;
    $('#myTable').DataTable().destroy();

    $("#year").off('change', onAttributeChange_4091d8da);
    $("#month").off('change', onAttributeChange_4091d8da);
    $("#partner").off('change', onAttributeChange_4091d8da);
}
